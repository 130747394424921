<template>
    <div class="index">
        <div class="cellList">
            <div class="cell-item" v-for="(item, index) in cellList" :key="index" :data-url="item.jumpUrl" @click="jump">
                <div class="cell-item-left">
                    <span :class="['cell-icon',item.iconClass]"></span>
                </div>
                <div :class="['cell-item-right',index==0?'cell-border-none':'',index==4?'cell-border-grey':'']">
                    <span class="cell-title">{{item.title}}</span>
                    <span class="cell-content">
                        {{item.content}}
                        <span class="cell-content" v-if="item.jumpUrl">
                            <img :src="require('@/assets/icons/arrow_right.png')" alt="right" class="right" />
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <div class="btnBox">
            <button class="smallBtn" @click="logOut">退出登录</button>
        </div>
    </div>
</template>
<script>
import filter from '../../../filter/index'
export default {
    name: 'mine',
    data() {
        return {
            cellList:[]
        }
    },
    mounted() {
        document.querySelector('body').setAttribute('style', 'background-color:#f5f5f5');
        let userData = this.$common.getUserData();
        this.cellList=[{
                title: '名称',
                content: userData.companyName,
                iconClass: 'icon2',
            },
            {
                title: '身份',
                content: filter.levelVal(userData.level),
                iconClass: 'icon4',
            },
            {
                title: '联系人',
                content: userData.contactPerson,
                iconClass: 'icon5',
            },
            {
                title: '手机号',
                content: userData.contactMobile,
                iconClass: 'icon3',
            },
            {
                title: '修改密码',
                jumpUrl: 'modifyPassword',
                iconClass: 'icon1',
            }
        ]
    },
    beforeDestroy() {
        document.querySelector('body').removeAttribute('style')
    },
    methods: {
        jump(e) {
            let urlVal = e.currentTarget.dataset.url;
            urlVal && this.$router.push(urlVal);
        },
        logOut(){
            let validation = this.$common.getToken();
            this.$api.userLogout({validation}).then(() => {
                this.$toast.clear();
                this.$toast({
                  type: 'success',
                  message: '退出成功',
                  onClose: () => {
                    this.$store.commit('logout');
                    this.$router.replace('/login');
                  }
                })
            })
        }
    },
}
</script>
<style scoped lang="less">
@import url("../../../assets/less/mobile/mine.less");
@import url("../../../assets/less/mobile/common.less");
</style>