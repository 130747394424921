<template>
  <div class="content">
    <div class="form_box">
      <div class="form_cell">
        <p class="form_tips">名称</p>
        <div class="form_content">
          <input
            type="text"
            placeholder="请输入公司名称"
            v-model="form.name"
            :readonly="type == 'check'"
          />
        </div>
      </div>
      <div class="form_cell">
        <p class="form_tips">身份</p>
        <div class="form_content">
          <el-select
            v-model="form.identify"
            placeholder="请选择"
            :disabled="type == 'check'"
          >
            <template v-for="item in identifyList">
              <el-option :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </template>
          </el-select>
        </div>
      </div>
      <div class="form_cell" v-if="form.identify > 2">
        <p class="form_tips">所属一级</p>
        <div class="form_content">
          <el-select
            @change="chooseFirst"
            v-model="form.firstLevel"
            placeholder="请选择"
            :disabled="type == 'check'"
          >
            <template v-for="item in oldLevel">
              <el-option
                :key="item.id"
                :label="item.name"
                :value="item.id"
                v-if="item.id != companyId"
              >
              </el-option>
            </template>
          </el-select>
        </div>
      </div>
      <div class="form_cell" v-if="form.identify > 3">
        <p class="form_tips">所属二级</p>
        <div class="form_content">
          <el-select
            v-model="form.secondLevel"
            placeholder="请选择"
            :disabled="type == 'check' || form.firstLevel == ''"
          >
            <template v-for="item in secondList">
              <el-option
                :key="item.id"
                :label="item.name"
                :value="item.id"
                v-if="item.id != companyId"
              >
              </el-option>
            </template>
          </el-select>
        </div>
      </div>
      <div class="form_cell form_more">
        <p class="form_tips">权限设置</p>
        <div class="form_content form_auth">
          <div class="cell" v-for="(item, index) in authList" :key="index">
            <el-checkbox
              :label="index"
              v-model="item.hasRole"
              :disabled="type == 'check'"
              @change="handleCheckGroup"
              >{{ item.urlName }}
            </el-checkbox>
            <div class="cell_child" v-if="item.roles">
              <el-checkbox
                v-for="(cell, idx) in item.roles"
                v-model="cell.hasRole"
                :label="idx"
                :key="idx"
                :disabled="type == 'check'"
                @change="handleSingleCheck(index, idx, cell.hasRole)"
                > <span class="url_name">{{ cell.urlName }}</span>
                <div class="cell_option">
                  <template v-for="(child, ixs) in cell.roles">
                    (<el-checkbox 
                    :key="ixs"
                    :label="child.urlName"
                    :disabled="type == 'check'"
                    v-model="child.hasRole"
                    @change="handleCheckOther(index, idx, child.hasRole)"
                    >{{ child.urlName }}</el-checkbox>)
                  </template>
                </div>
              </el-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="option_btn" v-if="type != 'check'">
      <button class="btn orange" @click="submit">保存</button>
      <button class="btn gery" @click="reback">取消</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "manage",
  data() {
    return {
      form: {
        name: "",
        identify: "",
        auth: "",
        firstLevel: "",
        secondLevel: "",
        index: 0,
      },
      type: "",
      level: "",
      identifyList: [
        {
          title: "公司",
          id: 2,
        },
        {
          title: "物业",
          id: 3,
        },
        {
          title: "楼盘/小区",
          id: 4,
        },
      ],
      authList: [],
      oldLevel: [],
      secondList: [],
      companyId: "",
      superiorId: "",
    };
  },
  created() {
    const query = this.$route.query;
    if (query.type) {
      this.type = query.type;
    }
    this.level = query.level || "";
    this.companyId = query.id || "";
    this.getAuth();
    if (query.level && query.level > 1) {
      this.form.identify = parseInt(query.level);
      this.getPre(query.level - 1);
    }
  },
  methods: {
    handleCheckGroup(e, item) {
      const check = e;
      const value = item.target.value;
      this.authList[value].hasRole = check;
      let list = this.authList[value].roles;
      if (!list) {
        return;
      }
      // 一级
      list.forEach((cell, index) => {
        this.$set(list[index], "hasRole", check);
        if (!cell.roles) {
          return;
        }
        // 二级
        cell.roles.forEach((item, idx) => {
          this.$set(list[index].roles[idx], "hasRole", check);
          if (!item.roles) {
            return;
          }
          // 三级
          item.roles.forEach((cell, ids) => {
            this.$set(list[index].roles[idx].roles[ids], "hasRole", check);
          });
        });
      });
    },
    handleSingleCheck(index, idx, item) {
      let list = this.authList[index].roles[idx];
      if (list.roles) {
        list.roles.forEach((e) => {
          this.$set(e, "hasRole", item);
        });
      }
      this.calculateItem(index);
    },
    // 选择子项
    handleCheckOther(index, idx, item) {
      let list = this.authList[index].roles[idx];
      if (item) {
        this.$set(list, "hasRole", item);
      }
      this.calculateItem(index);
    },
    // 合计已选中数据
    calculateItem(e) {
      let list = this.authList[e].roles;
      let i = 0;
      list.forEach((item) => {
        if (item.hasRole) {
          i += 1;
        }
      });
      if (i < 1) {
        this.authList[e].hasRole = false;
      } else {
        this.authList[e].hasRole = true;
      }
    },
    // 取消
    reback() {
      this.$router.back();
    },
    // 保存
    submit() {
      const form = this.form;
      const rolesIds = this.calculateIds();
      let superiorId = "";
      if (form.name == "") {
        this.$message.error("请输入公司名称");
        return;
      }
      if (form.identify == "") {
        this.$message.error("请选择身份");
        return;
      }
      if (form.identify > 2) {
        if (form.identify == 3) {
          superiorId = form.firstLevel;
        } else if (form.identify == 4) {
          superiorId = form.secondLevel;
        }
        if (superiorId == "") {
          this.$message.error("请选择所属上级");
          return;
        }
      }
      let data = {
        name: form.name,
        level: form.identify,
        id: this.companyId,
        rolesIds,
        superiorId: superiorId,
      };
      this.$api.admin.addOrModifyCompanyInfo(data).then(
        () => {
          this.$message.success("添加成功");
          this.$router.push("/person/list");
        },
        (error) => {
          this.$message.error(error.msg);
        }
      );
    },
    calculateIds() {
      const list = this.authList;
      let arr = [];
      list.forEach((cell) => {
        if (cell.hasRole) {
          arr.push(cell.id);
        }
        if (!cell.roles) {
          return;
        }
        // 二级
        cell.roles.forEach((item) => {
          if (item.hasRole) {
            arr.push(item.id);
          }
          if (!item.roles) {
            return;
          }
          // 三级
          item.roles.forEach((child) => {
            if (child.hasRole) {
              arr.push(child.id);
            }
          });
        });
      });
      return arr.toString();
    },
    // 获取公司权限
    getAuth() {
      this.$api.admin
        .getCompanyRolesDetails({
          companyId: this.companyId || 14,
        })
        .then(
          (res) => {
            let data = res.data;
            this.$nextTick(() => {
              this.authList = data;
            });
          },
          (error) => {
            this.$message.error(error.msg);
          }
        );
    },
    getDetail() {
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(255,255,255, 0.5)",
      });
      this.$api.admin
        .getCompanyDetails({
          id: this.companyId,
        })
        .then(
          (res) => {
            loading.close();
            let data = res.data;
            this.form.name = data.name;
            this.form.identify = data.level;
            this.superiorId = Number(data.superiorId) || "";
            if (data.level == 4) {
              this.form.secondLevel = Number(data.parent.id);
              this.form.firstLevel = Number(data.parent.parent.id);
              this.$nextTick(() => {
                this.secondList = this.oldLevel.find(item => {
                  return item.id == data.parent.parent.id;
                }).childList;
              })
            } else if (data.level == 3) {
              this.form.firstLevel = Number(data.parent.id);
              this.$nextTick(() => {
                 this.secondList = this.oldLevel.find(item => {
                  return item.id == data.parent.id;
                }).childList;
              })
            }
          },
          (error) => {
            loading.close();
            this.$message.error(error.msg);
          }
        )
        .catch(() => {
          loading.close();
        });
    },
    // 获取上级公司
    getPre() {
      this.$api.admin
        .getCompanyDropDownList({
          level: 2,
        })
        .then(
          (res) => {
            // 二级
            let data = res.data;
            data.forEach((e) => {
              if (!e.childList) {
                e.childList = [];
              }
            });
            this.oldLevel = data;
            if(this.companyId){
              this.getDetail();
            }else{
              this.secondList = data[0].childList;
            }
          },
          (error) => {
            this.$message.error(error.msg);
          }
        );
    },
    // 选择所属一级
    chooseFirst(e) {
      const oldLevel = this.oldLevel;
      const result = oldLevel.find((item) => {
        return item.id == e;
      });
      this.form.secondLevel = "";
      this.secondList = result.childList;
    },
  },
};
</script>

<style scoped lang="less">
@import url("../../../../assets/less/pc/person/manage.less");
</style>