<template>
  <div class="person">
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "person",
};
</script>

<style lang="less" scoped>
.person {
  padding: 80px 0 0 229px;
  min-width: 960px;
  .container {
    background: #f9f9fc;
    padding: 20px;
    height: calc(100vh - 120px);
    overflow-y: auto;
  }
}
</style>