<template>
  <div class="content">
    <div class="filter_box">
      <div class="filter_left">
        <input type="text" v-model="name" placeholder="名称" />
        <el-date-picker
          v-model="range"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="picker"
        >
        </el-date-picker>
        <button class="submit">筛选</button>
      </div>
      <!-- <button class="export">导出</button> -->
    </div>
    <div class="trend_table">
      <div class="table_box">
        <el-table :data="tableData" style="width: 100%" :stripe="true">
          <el-table-column prop="id" label="id"  width="80"></el-table-column>
          <el-table-column prop="url" label="页面URL" width="220"></el-table-column>
          <el-table-column prop="access" label="访问次数"> </el-table-column>
          <el-table-column prop="user" label="访问用户"> </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page_box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="total, prev, pager, next, jumper"
        background
        :total="2"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "page",
  data() {
    return {
      name: "",
      range: "",
      currentPage: 1,
      picker: {
        disabledDate: (value) => {
          if (new Date(value).getTime() > new Date().getTime()) {
            return true;
          }
          return false;
        },
      },
      tableData: [{
          id: 1,
          url: '广州市海格洛科技有限公司',
          access: '12,900',
          user: '10,098'
      },{
          id: 2,
          url: '广州市海格洛科技有限公司',
          access: '12,900',
          user: '10,098'
      }],
    };
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(e) {
      this.currentPage = e;
    },
  },
};
</script>

<style scoped lang="less">
@import url("../../../../assets/less/pc/analyze/page.less");
</style>