import store from '../store/index';

let method = {}

// 获取Token
method.getToken = function(){
    if(store.state.windowWidth > 1024){
        return false
    }
    return store.state.token ? store.state.token : localStorage.getItem("token");
}

// 获取UserData
method.getUserData = function(){
    if(store.state.windowWidth > 1024){
        return false
    }
    if (store.state.userData) {
        if(typeof store.state.userData == 'string'){
            return JSON.parse(store.state.userData)
        }else{
            return store.state.userData
        }
    }else{
        return JSON.parse(localStorage.getItem("userData"))
    }
}

// 获取Level
method.getLevel = function(){
    if(store.state.windowWidth > 1024){
        return false
    }
    return store.state.level ? store.state.level : localStorage.getItem("level");
}

// 获取公司列表
method.getCompanyList = function(){
    if(store.state.windowWidth > 1024){
        return false
    }
    if (store.state.companyList && store.state.companyList.length>0) {
        return store.state.companyList
    }else{
        return JSON.parse(localStorage.getItem("companyList")) || []
    }
}

// 获取物业列表
method.getPropertyList = function(){
    if(store.state.windowWidth > 1024){
        return false
    }
    if (store.state.propertyList && store.state.propertyList.length>0) {
        return store.state.propertyList
    }else{
        return JSON.parse(localStorage.getItem("propertyList")) || []
    }
}

export default method;