"use strict";

import Vue from 'vue';
import axios from "axios";
import { Message } from 'element-ui';
import "../assets/elementTheme/theme/index.css";
import Store from '../store/index';
// Full config:  https://github.com/axios/axios#request-config
const BASEURL = '';
axios.defaults.baseURL = '/api';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: '/api',
  // timeout: 60 * 1000, // Timeout
  // withCredentials: false, // Check cross-site Access-Control
};

// error Tips
const errorMessage = {
  400: '请求参数错误',
  401: '权限不足, 请重新登录',
  403: '服务器拒绝本次访问',
  404: '请求资源未找到',
  500: '内部服务器错误',
  501: '服务器不支持该请求中使用的方法',
  502: '网关错误',
  504: '网关超时',
}

const _axios = axios.create(config);

// axios 拦截器
_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
let time = 0;
let forbidden = 0;
// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // 登录失效拦截
    if(response.data.status == 300 && Store.state.windowWidth > 1024){
      time += 1;
      if(time > 1){
        return
      }
      Message({
        message: response.data.msg,
        type: 'error'
      })
      console.log("登录失效");
      Store.commit('updataToken', '');
      localStorage.clear();
      window.location.href = BASEURL + '/#/login';
      return
    }
    if(response.data.status == 403 && Store.state.windowWidth > 1024){
      // 无权限拦截
      forbidden += 1;
      if(forbidden > 1){
        return
      }
      window.location.href = BASEURL + '/#/403';
      forbidden = 0;
      return false
    }
    // Do something with response data
    return response;
  },
  function(error) {
    const tips = error.response.status in errorMessage ? errorMessage[error.response.status] : error.response.data.msg;
    Message({
      message: tips,
      type: 'error'
    })
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin; 