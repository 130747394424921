// 404 页面
import four from '../views/error/404';
// 登录
import login from '../views/mobile/login/index';

/**
 * 首页包含子页面
 */
//首页
import index from '../views/mobile/index/index';
// 首页数据
import data from '../views/mobile/index/data';
//物业数据列表
import property from '../views/mobile/index/property';
// 用户数据
import mine from '../views/mobile/mine/index';
// 修改密码
import modifyPassword from '../views/mobile/modifyPassword/index';
// 小区详情
import residentialDetails from '../views/mobile/residentialDetails/index';

const mobileRouters = [{
    name: 'index',
    path: '/',
    component: index,
    meta: {
        requireAuth: true
    },
    redirect: '/index',
    children: [{
        path: 'index',
        component: data,
        meta: {
            tab: "0",
            title: '餸鲜生数据助手'
        },
    }, {
        path: 'property',
        component: property,
        meta: {
            title: '小区数据概览'
        }
    }]
},
{
    name: 'login',
    path: '/login',
    component: login
}, {
    name: 'mine',
    path: '/mine',
    component: mine,
    meta: {
        requireAuth: true,
        tab: "1",
        title: '我的账号'
    }
}, {
    name: 'modifyPassword',
    path: '/modifyPassword',
    component: modifyPassword,
    meta: {
        requireAuth: true,
        title: '修改密码'
    }
}, {
    name: 'residentialDetails',
    path: '/residentialDetails',
    component: residentialDetails,
    meta: {
        requireAuth: true,
        title: '小区数据概览'
    }
},{
    path: '/404',
    component: four
},{
    path: "*",
    redirect: "/404"
}]
export default mobileRouters