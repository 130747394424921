
let levelObj = [
    {
       leve:1,
       val:'内部人员'
    },
    {
       leve:2,
       val:'公司'
    },
    {
       leve:3,
       val:'物业'
    },
    {
       leve:4,
       val:'小区'
    },
    {
       leve:5,
       val:'团长'
    },
]


/**
 * 保留几位小数
 * @n 保留的位数
 */
function intFilter(value, n = 0) {
    value ? value : value = 0
    return parseFloat(value).toFixed(n)
}
/**
 * 截取字符串
 * n 截取多少位
 * sort 正序或者倒序inverted
 */
function cutOut(value, n = 0, sort = 'inverted') {
    value = String(value);
    var disLength = value.length;
    if (sort == 'inverted') {
        return value.substring(disLength - n, disLength);
    } else {
        return value.substring(0, n);
    }
}

/**
 * 获取权限值
 */
function levelVal(leve,n=0) {
    leve = parseInt(leve);
    let val = levelObj.findIndex((value) => {
        return value.leve == leve
    });
    return levelObj[val+n].val
}

export default {
    intFilter,
    cutOut,
    levelVal
}