<template>
  <div class="slidebar" :style="list.length < 1 ? 'width:0' : ''">
    <div class="page_header" v-if="headerName">
      <el-page-header @back="goBack" :content="headerName"></el-page-header>
    </div>
    <div class="slide_content">
      <div class="slide_list" v-if="current >= 0">
        <div
          :class="['slide_cell', item.active ? 'active' : '']"
          v-for="(item, index) in currentList"
          :key="index"
          :data-index="index"
          @click="jumpLink"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getAuth from '../../routers/allRouter';
export default {
  name: "slidebar",
  data() {
    return {
      list: [
        {
          title: "系统首页",
          link: '/',
          current: 0,
          child: [
            {
              title: "数据概览",
              link: "/index",
              active: false,
            },
          ],
        },
        {
          current: 1,
          title: "流量分析",
          link: '/analyze',
          child: [
            {
              title: "数据概览",
              link: "/analyze/index",
              active: false,
            },
            {
              title: "趋势分析",
              link: "/analyze/trend",
              active: false,
            },
            {
              title: "页面分析",
              link: "/analyze/page",
              active: false,
            },
          ],
        },
        {
          title: "订单分析",
          link: '/order',
          current: 2,
          child: [
            {
              title: "交易概览",
              link: "/order/index",
              hasChild: true,
              active: false,
              child: ["/order/data"],
            },
            {
              title: "分类统计",
              link: "/order/classify",
              active: false,
            },
          ],
        },
        {
          title: "分享传播",
          link: '/share',
          current: 3,
          child: [
            {
              title: "分享趋势",
              link: "/share/index",
              active: false,
            },
            {
              title: "用户分享",
              link: "/share/user",
              active: false,
            },
            {
              title: "页面分享",
              link: "/share/page",
              active: false,
            },
          ],
        },
        {
          title: "人员管理",
          link: '/person',
          current: 4,
          child: [
            {
              title: "合作伙伴",
              link: "/person/index",
              active: false,
              hasChild: true,
              child: ["/person/edit"],
            },
            {
              title: "管理列表",
              link: "/person/list",
              active: false,
              hasChild: true,
              child: ["/person/manage"],
            },
            {
              title: "管理人员",
              link: "/person/auth",
              active: false,
              hasChild: true,
              child: ["/person/editAuth"],
            }
          ],
        },
      ],
      current: -1,
      currentList: [],
      headerName: ''
    };
  },
  watch: {
    $route: function (to,from) {
      if(to.path == '/403'){
        this.current = from.meta.current;
      }else{
        this.current = to.meta.current;
        if(to.meta.name && from){
          this.headerName = to.meta.name;
        }else{
          this.headerName = ''; 
        }
        this.checkRoute();
        this.checkActive();
      }
    },
  },
  created() {
    this.current = this.$route.meta.current;
    this.checkRoute();
    this.checkActive();
    const headerName = this.$route.meta.name;
    if(headerName){
      this.headerName = headerName;
    }else {
      this.headerName = '';
    }
  },
  methods: {
    checkActive() {
      let current = this.current;
      let list = this.list;
      let path = this.$route.path;
      if (current > -1 && typeof current == "number") {
        const data = list.find(item => {
          return item.current == current;
        })
        if(!data){
          return;
        }
        data.child.forEach((e, index) => {
          if (e.link == path) {
            this.$set(data.child[index], "active", true);
          } else {
            this.$set(data.child[index], "active", false);
          }
          if (e.hasChild) {
            const result = e.child.find((item) => {
              return path == item;
            });
            if (result) {
              this.$set(data.child[index], "active", true);
            }
          }
        });
        this.currentList = data.child;
      }
    },
    // 跳转页面
    jumpLink(e) {
      const index = e.currentTarget.dataset.index;
      const path = this.$route.path;
      const current = this.current;
      const list = this.list.find(item => {
        return item.current == current;
      })
      const link = list.child[index].link;
      if (path == link) {
        return;
      }
      this.$router.push({
        path: link,
      });
    },
    goBack() {
      this.$router.back();
    },
    // 检出路由
    checkRoute(){
      const rawAuth = this.$store.state.rawAuth || '[]';
      if(!rawAuth || rawAuth == 'root'){
        return
      }
      let auth = getAuth(rawAuth);
      let arr = [];
      auth.forEach(e => {
        let result = this.filter(e);
        if(result){
          // 拿出拥有的路由
          let child = [];
          e.children.forEach(item => {
            if(result.child){
              let data = this.filter(result.child, item);
              if(data){
                // console.log(data)
                if(item.meta.roles){
                  // 三级操作权限等
                  if(data.hasChild){
                    const hasEdit = item.meta.roles.find(key => {
                      return key.routing == data.child[0];
                    });
                    if(!hasEdit){
                      data.hasChild = false;
                      data.child = [];
                    }
                  }
                }else{
                  if(data.hasChild){
                    data.hasChild = false;
                    data.child = [];
                  }
                }
                child.push(data);
              }
            }
          })
          result.child = child;
          arr.push(result)
        }
      });
      this.$nextTick(() => {
         this.list = arr;
      })
    },
    filter(params,level){
      const list = this.list;
      let result = null;
      if(!level){
        result = list.find(item => {
          return item.link == params.path
        });
      }else{
        result = params.find(item => {
          return item.link == level.path
        })
      }
      return result;
    }
  },
};
</script>

<style scoped lang="less">
.slidebar {
  position: fixed;
  top: 80px;
  left: 0;
  height: calc(100vh - 80px);
  overflow: hidden;
  background: #fff;
  z-index: 50;
  width: 229px;
  .slide_content {
    height: 100%;
    overflow-y: auto;
    .slide_cell {
      padding: 32px 0 0 57px;
      font-size: 18px;
      cursor: pointer;
    }
    .slide_cell:hover {
      color: #ff7930;
    }
    .active {
      color: #ff7930;
    }
  }
  .page_header{
    padding: 10px 20px 0;
  }
}
</style>