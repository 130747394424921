<template>
  <div class="content">
    <div class="filter_box">
      <div class="filter_left">
        <div class="filter_cell">
          <input type="text" placeholder="名称" v-model="name" />
        </div>
        <div class="filter_cell">
          <el-select
            v-model="classify"
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              v-for="item in classifyList"
              :key="item.id"
              :label="item.className"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="filter_cell">
          <el-date-picker
            v-model="range"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="picker"
          >
          </el-date-picker>
        </div>
        <div class="filter_cell">
          <button class="submit" @click="filter">筛选</button>
        </div>
      </div>
      <!-- <div class="filter_right">
        <button class="btn export">导出</button>
      </div> -->
    </div>
    <div class="classify_table">
      <div class="table_box">
        <el-table :data="tableData" style="width: 100%" :stripe="true">
          <el-table-column prop="id" label="id" width="80"></el-table-column>
          <el-table-column prop="className" label="分类名称" width="180"></el-table-column>
          <el-table-column
            prop="orderCount"
            label="订单交易数"
          ></el-table-column>
          <el-table-column
            prop="totalPrice"
            label="订单交易额"
          ></el-table-column>
          <el-table-column
            prop="customerPrice"
            label="客单价"
          ></el-table-column>
          <el-table-column
            prop="averageDayTransactionsCount"
            label="日均交易数"
          ></el-table-column>
          <el-table-column
            prop="averageDayTransactionsPrice"
            label="日均交易额"
          ></el-table-column>
        </el-table>
      </div>
      <div class="loading" v-if="loading">
        <i class="el-icon-loading"></i>
      </div>
    </div>
    <div class="page_box">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="rows"
        layout="total, prev, pager, next, jumper"
        background
        :total="totalList"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "classify",
  data() {
    return {
      name: "",
      classify: "",
      range: "",
      classifyList: [],
      picker: {
        disabledDate: (value) => {
          const time = new Date(value).getTime();
          if (time > new Date().getTime() || time < new Date('2020-01-01 00:00:00').getTime()) {
            return true;
          }
          return false;
        },
      },
      tableData: [],
      currentPage: 1,
      rows: 10,
      totalList: 0,
      loading: true,
    };
  },
  created() {
    this.getClassify();
    this.getList();
  },
  methods: {
    handleCurrentChange(e) {
      this.currentPage = e;
      this.getList();
    },
    filter() {
      this.currentPage = 1;
      this.getList();
    },
    getClassify() {
      this.$api.admin.getClassificationDropDown({}).then(
        (res) => {
          this.$nextTick(() => {
            this.classifyList = res.data;
          });
        },
        (error) => {
          this.$message.error(error.msg);
        }
      );
    },
    getList() {
      const page = this.currentPage;
      const rows = this.rows;
      const className = this.name;
      const range = this.range;
      const classId = this.classify;
      let data = {
        page,
        rows,
      };
      if (className) {
        data.className = className;
      }
      if (range) {
        data.startDate = range[0];
        data.endDate = range[1];
      }
      if (classId) {
        data.classId = classId;
      }
      this.loading = true;
      this.$api.admin.getClassificationStatistics(data).then(
        (res) => {
          this.loading = false;
          if (page == 1) {
            this.totalList = res.data.total;
          }
          this.tableData = res.data.rows;
        },
        (error) => {
          this.loading = false;
          this.$message.error(error.msg || "未知错误");
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../../../assets/less/pc/order/classify.less");
</style>