<template>
  <div class="content">
    <div class="form_box">
      <div class="form_cell">
        <p class="form_tips">名称 <span class="red">*</span></p>
        <div class="form_content">
          <input
            type="text"
            v-model="form.contactPerson"
            placeholder="请输入"
            :readonly="type == 'check'"
          />
        </div>
      </div>
      <div class="form_cell">
        <p class="form_tips">身份</p>
        <div class="form_content">
          <input
            type="text"
            v-model="form.identity"
            placeholder="请输入"
            :readonly="type == 'check'"
          />
        </div>
      </div>
      <div class="form_cell">
        <p class="form_tips">所属公司 <span class="red">*</span></p>
        <div class="from_content">
          <el-select
            v-model="form.company"
            placeholder="请选择"
            :disabled="type == 'check'"
          >
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="form_cell">
        <p class="form_tips">联系方式 <span class="red">*</span></p>
        <div class="form_content">
          <input
            type="text"
            v-model="form.contactMobile"
            placeholder="请输入"
            maxlength="11"
            :readonly="type == 'check'"
          />
        </div>
      </div>
      <div class="form_cell" v-if="type != 'check'">
        <p class="form_tips">密码 <span class="red">*</span></p>
        <div class="form_content">
          <input
            type="password"
            v-model="form.password"
            placeholder="请输入"
            autocomplete="new-password"
            :readonly="type == 'check'"
          />
        </div>
      </div>
    </div>
    <div class="form_submit" v-if="type != 'check'">
      <button class="btn orange" @click="submit">保存</button>
      <button class="btn gery" @click="reback">取消</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "editAuth",
  data() {
    return {
      form: {
        contactPerson: "",
        contactMobile: "",
        company: "海格洛",
        password: "",
        identity: "",
      },
      level: 1,
      type: "",
      companyList: [{
        id: 2,
        name: '海格洛'
      }],
      id: "",
    };
  },
  created() {
    const query = this.$route.query;
    this.id = query.id || "";
    // this.getPre(1);
    if (query.type) {
      this.type = query.type;
      if (query.type == "check" || query.type == "edit") {
        this.getDetail();
      }
    }
  },
  methods: {
    // 保存
    submit() {
      const form = this.form;
      if (form.contactPerson == "") {
        this.$message.error("请输入名称");
        return;
      }
      if (form.company == "") {
        this.$message.error("请选择所属公司");
        return;
      }
      if (form.contactMobile == "") {
        this.$message.error("请输入联系方式");
        return;
      }
      if (form.password == "") {
        this.$message.error("请输入密码");
        return;
      }
      let data = {
        id: this.id,
        companyId: 2,
        level: this.level,
        contactPerson: form.contactPerson,
        contactMobile: form.contactMobile,
      }
      if(form.password){
        data.password = form.password
      }
      if(form.identity){
        data.identity = form.identity;
      }
      this.$api.admin
        .addOrModifyPersonnelInfo(data)
        .then(
          () => {
            this.$message.success("保存成功");
            this.$router.push("/person/auth");
          },
          (error) => {
            this.$message.error(error.msg);
          }
        );
    },
    reback() {
      this.$router.back();
    },
    // 获取上级公司
    getPre(level) {
      this.$api.admin
        .getCompanyDropDownList({
          level: level,
        })
        .then(
          (res) => {
            this.companyList = res.data;
          },
          (error) => {
            this.$message.error(error.msg);
          }
        );
    },
    // 获取详情
    getDetail() {
      const loading = this.$loading({
          lock: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(255,255,255, 0.2)'
      });
      this.$api.admin
        .getPersonnelDetails({
          id: this.id,
        })
        .then(
          (res) => {
            loading.close();
            const data = res.data;
            this.form = {
              contactPerson: data.contactPerson,
              contactMobile: data.contactMobile,
              company: data.name,
              password: "",
              identity: data.identity,
            }
          },
          (error) => {
            loading.close();
            this.$message.error(error.msg);
          }
        ).catch(() => {
          loading.close();
        })
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../../../assets/less/pc/person/edit.less");
</style>