<template>
  <div class="content">
    <div class="filter_box">
      <div class="filter_left">
        <!-- <input type="text" v-model="name" placeholder="名称" /> -->
        <div class="select_box">
          <el-select v-model="type" placeholder="请选择订单状态">
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select_box">
          <el-select
            v-model="company"
            placeholder="请选择"
            filterable
            @change="chooseFirst"
          >
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select_box">
          <el-select
            v-model="property"
            placeholder="请选择"
            clearable
            filterable
            :disabled="company == ''"
            @change="chooseSecond"
            @clear="clear(2)"
          >
            <el-option
              v-for="item in propertyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select_box">
          <el-select
            v-model="third"
            @change="chooseThird"
            placeholder="请选择"
            clearable
            filterable
            :disabled="property == ''"
            @clear="clear(3)"
          >
            <el-option
              v-for="item in thirdList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <el-date-picker
          v-model="range"
          :type="pickerType"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="picker"
        >
        </el-date-picker>
        <button class="submit" @click="filter">筛选</button>
      </div>
      <!-- <button class="export">导出</button> -->
    </div>
    <div class="trend_table">
      <div class="table_box">
        <el-table :data="tableData" style="width: 100%" :stripe="true">
          <el-table-column prop="id" label="id" width="80"></el-table-column>
          <el-table-column prop="name" label="公司名称" width="180"></el-table-column>
          <el-table-column
            prop="orderCount"
            :label="orderStatus == 1 ? '订单交易数' : '订单退款数'"
          ></el-table-column>
          <el-table-column
            prop="totalPrice"
            :label="orderStatus == 1 ? '订单交易额' : '订单退款额'"
          ></el-table-column>
          <el-table-column
            prop="customerPrice"
            label="客单价"
          ></el-table-column>
          <el-table-column
            prop="averageDayTransactionsCount"
            :label="orderStatus == 1 ? '日均交易数' : '日均退款数'"
          ></el-table-column>
          <el-table-column
            prop="averageDayTransactionsPrice"
            :label="orderStatus == 1 ? '日均交易额' : '日均退款额'"
          ></el-table-column>
          <el-table-column label="操作" v-if="roles.includes('view')">
            <template slot-scope="scope">
              <div class="table_option">
                <router-link
                  class="btn blue"
                  :to="
                    '/order/data?id=' +
                    scope.row.id +
                    '&orderStatus=' +
                    orderStatus
                  "
                  >数据</router-link
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="loading" v-if="loading">
        <i class="el-icon-loading"></i>
      </div>
    </div>
    <div class="page_box">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="total, prev, pager, next, jumper"
        background
        :total="totalList"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      name: "",
      range: "",
      currentPage: 1,
      rows: 10,
      picker: {
        disabledDate: (value) => {
          const time = new Date(value).getTime();
          if (
            time > new Date().getTime() ||
            time < new Date("2020-01-01 00:00:00").getTime()
          ) {
            return true;
          }
          return false;
        },
      },
      totalList: 0,
      tableData: [],
      company: "",
      companyList: [],
      property: "",
      propertyList: [],
      third: "",
      thirdList: [],
      pickerType: "daterange",
      companyId: "",
      loading: true,
      type: 1,
      typeList: [
        {
          name: "普通订单",
          id: 1,
        },
        {
          name: "退款订单",
          id: 40,
        },
      ],
      orderStatus: 10,
      roles: ['view'],
    };
  },
  watch: {
    $route: function () {
      this.checkRoute();
    },
  },
  created() {
    this.checkRoute();
    this.getPre();
  },
  methods: {
    handleCurrentChange(e) {
      this.currentPage = e;
      this.getList();
    },
    // 选择一级公司
    chooseFirst(e) {
      const result = this.companyList.find((item) => {
        return item.id == e;
      });
      if (!result.childList) {
        this.propertyList = [];
      } else {
        this.propertyList = result.childList;
      }
      this.property = "";
      this.third = "";
      this.companyId = e;
    },
    // 选择二级公司
    chooseSecond(e) {
      if (!e) {
        this.thirdList = [];
        this.third = "";
        return;
      }
      const result = this.propertyList.find((item) => {
        return item.id == e;
      });
      if (!result.childList) {
        this.thirdList = [];
      } else {
        this.thirdList = result.childList;
      }
      this.third = "";
      this.companyId = e;
    },
    // 选择三级公司
    chooseThird(e) {
      this.companyId = e;
    },
    // 清空选项
    clear(e) {
      if (e == 3) {
        this.companyId = this.property;
      } else if (e == 2) {
        this.companyId = this.company;
      }
    },
    // 筛选
    filter() {
      this.getList();
    },
    getPre() {
      this.$api.admin
        .getCompanyDropDownList({
          level: 2,
        })
        .then(
          (res) => {
            this.companyList = res.data;
            this.company = parseInt(res.data[0].id);
            this.companyId = res.data[0].id;
            this.propertyList = res.data[0].childList || [];
            this.getList();
          },
          (error) => {
            this.$message.error(error.msg);
          }
        );
    },
    getList() {
      const page = this.currentPage;
      const rows = this.rows;
      const name = this.name;
      const property = this.property;
      const third = this.third;
      const range = this.range;
      const companyId = this.companyId;
      const type = this.type;
      let level = null;
      if (!third && !property) {
        level = 2;
      } else if (!third && property) {
        level = 3;
      } else if (third) {
        level = 4;
      }
      let data = {
        page,
        rows,
        companyId,
        level,
      };
      if (range) {
        data.startDate = range[0];
        data.endDate = range[1];
      }
      if (name) {
        data.name = name;
      }
      if (type == 1) {
        data.orderStatus = 10;
      }else{
        data.orderStatus = 40;
      }
      this.loading = true;
      this.$api.admin.getTransactionList(data).then(
        (res) => {
          this.loading = false;
          this.orderStatus = type;
          if (page == 1) {
            this.totalList = res.data.total;
          }
          this.$nextTick(() => {
            let arr = res.data.rows;
            this.tableData = arr;
          });
        },
        (error) => {
          this.loading = false;
          this.$message.error(error.msg);
        }
      );
    },
    checkRoute() {
      let rawAuth = this.$store.state.rawAuth;
      if(rawAuth == 'root'){
        return 
      }
      let roles = this.$route.meta.roles;
      let arr = [];
      if (roles && roles.length > 0) {
        roles.forEach((e) => {
          arr.push(e.operating);
        });
      }
      this.roles = arr;
    },
  },
};
</script>

<style scoped lang="less">
@import url("../../../../assets/less/pc/order/index.less");
</style>