<template>
  <div class="content">
    <div class="filter_box">
      <div class="filter_day">
        <div
          :class="['day', item.value == day ? 'active' : '']"
          :data-index="index"
          v-for="(item, index) in dayList"
          :key="index"
          @click="chooseDay"
        >
          {{ item.title }}
        </div>
      </div>
      <el-date-picker
        v-model="range"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        :picker-options="picker"
      >
      </el-date-picker>
      <button class="submit">筛选</button>
    </div>
    <div class="person_data">
      <div class="person_cell">
        <p class="person_num">26,321</p>
        <div class="person_other">
          <p class="person_tips">访问用户</p>
          <p class="person_meaasge green">
            前天 -32% <i class="el-icon-bottom"></i>
          </p>
        </div>
      </div>
      <div class="person_cell">
        <p class="person_num">26,321</p>
        <div class="person_other">
          <p class="person_tips">注册用户</p>
          <p class="person_meaasge green">
            前天 -32% <i class="el-icon-bottom"></i>
          </p>
        </div>
      </div>
      <div class="person_cell">
        <p class="person_num">26,321</p>
        <div class="person_other">
          <p class="person_tips">新增用户</p>
          <p class="person_meaasge red">
            前天 +32% <i class="el-icon-top"></i>
          </p>
        </div>
      </div>
    </div>
    <div class="realTime_box">
      <h4 class="real_time_title">实时访问量</h4>
      <div class="real_chart_box">
        <div id="real_chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "analyzeData",
  data() {
    return {
      dayList: [
        {
          title: "昨天",
          value: 0,
        },
        {
          title: "近7日",
          value: 7,
        },
        {
          title: "近30日",
          value: 30,
        },
        {
          title: "近90日",
          value: 90,
        },
      ],
      day: 0,
      range: "",
      picker: {
          disabledDate: (value) => {
              if(new Date(value).getTime() > new Date().getTime()){
                  return true
              }
              return false 
          }
      }
    };
  },
  mounted() {
    let time = [];
    let flute = [];
    for (let i = 0; i < 24; i++) {
      let random = Math.floor(Math.random() * 100);
      if (i < 10) {
        i = "0" + i;
      }
      time.push(i + ":00");
      flute.push(random);
    }
    let data = {
      time,
      flute
    };
    this.initData("real_chart", data);
  },
  methods: {
    chooseDay(e) {
      const index = e.currentTarget.dataset.index;
      this.day = this.dayList[index].value;
    },
    initData(params, data) {
      var myChart = echarts.init(document.getElementById(params));
      myChart.setOption({
        grid: {
          bottom: "5%",
          top: "5%"
        },
        color: ["#58CFFF"],
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            color: "#6C7293",
          },
          data: data.time,
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: "#F5F5F5",
            },
          },
          axisLabel: {
            fontSize: 12,
            color: "#6C7293",
          },
          type: "value",
        },
        series: [
          {
            name: "访问量",
            type: "bar",
            data: data.flute,
            barWidth: 20,
            itemStyle: {
              barBorderRadius: [10,10,0,0]
            }
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../../../assets/less/pc/analyze/index.less");
</style>