// 工具
import utils from '../assets/utils/utils';

/**
 * 首页模块
 */
// 入口页
import index from '../views/PC/index/index';
// 数据概览
import data from '../views/PC/index/pages/data';

/**
 * 流量分析模块
 */
// 入口页
import analyzIndex from '../views/PC/analyze/index';
// 流量分析数据概览
import analyzeData from '../views/PC/analyze/pages/index';
// 趋势分析
import analyzeTrend from '../views/PC/analyze/pages/trend';
// 页面分析
import analyzePage from '../views/PC/analyze/pages/page';

/**
 * 订单分析
 */
// 入口页
import order from '../views/PC/order/index';
// 数据分析
import orderIndex  from '../views/PC/order/pages/index';
// 数据分析子数据页面
import orderData from '../views/PC/order/pages/data';
// 分类统计
import orderClassify from '../views/PC/order/pages/classify';

/**
 * 分享传播
 */
// 入口页
import share from '../views/PC/share/index';
//分享趋势
import shareIndex from '../views/PC/share/pages/index';
// 用户分享
import shareUser from '../views/PC/share/pages/user';
// 页面分析
import sharePage from '../views/PC/share/pages/page';

/**
 * 人员管理
 */
// 入口页
import person from '../views/PC/person/index';
// 合作伙伴
import personIndex from '../views/PC/person/pages/index';
// 人员编辑
import personEdit from '../views/PC/person/pages/edit';
// 管理人员
import personAuth from '../views/PC/person/pages/auth';
// 管理列表
import personList from '../views/PC/person/pages/list';
// 新增管理人员
import personManage from '../views/PC/person/pages/manage';
// 新增人员
import personEditauth from '../views/PC/person/pages/editAuth';

let routers = [{
    name: '',
    path: '/',
    component: index,
    redirect: '/index',
    meta: {
        requireAuth: true,
        views: true,
        current: 0
    },
    children: [{
        name: '',
        path: '/index',
        component: data,
        meta: {
            requireAuth: true,
            views: true,
            current: 0
        }
    }]
},{
    name: '',
    path: '/analyze',
    component: analyzIndex,
    redirect: '/analyze/index',
    meta: {
        requireAuth: true,
        views: true,
        current: 0
    },
    children: [{
        name: '',
        path: '/analyze/index',
        component: analyzeData,
        meta: {
            requireAuth: true,
            views: true,
            current: 1
        }
    },{
        name: '',
        path: '/analyze/trend',
        component: analyzeTrend,
        meta: {
            requireAuth: true,
            views: true,
            current: 1
        }
    },{
        name: '',
        path: '/analyze/page',
        component: analyzePage,
        meta: {
            requireAuth: true,
            views: true,
            current: 1
        }
    }]
},{
    name: '',
    path: '/order',
    component: order,
    redirect: '/order/index',
    meta: {
        requireAuth: true,
        views: true,
        current: 2
    },
    children: [{
        name: '',
        path: '/order/index',
        component: orderIndex,
        meta: {
            requireAuth: true,
            views: true,
            current: 2
        }
    },{
        name: '',
        path: '/order/data',
        component: orderData,
        meta: {
            requireAuth: true,
            views: true,
            current: 2,
            name: '数据详情'
        }
    },{
        name: '',
        path: '/order/classify',
        component: orderClassify,
        meta: {
            requireAuth: true,
            views: true,
            current: 2
        }
    }]
},{
    name: '',
    path: '/share',
    component: share,
    redirect: '/share/index',
    meta: {
        requireAuth: true,
        views: true,
        current: 3
    },
    children: [{
        name: '',
        path: '/share/index',
        component: shareIndex,
        meta: {
            requireAuth: true,
            views: true,
            current: 3
        }
    },{
        name: '',
        path: '/share/user',
        component: shareUser,
        meta: {
            requireAuth: true,
            views: true,
            current: 3
        }
    },{
        name: '',
        path: '/share/page',
        component: sharePage,
        meta: {
            requireAuth: true,
            views: true,
            current: 3
        }
    }]
},{
    name: '',
    path: '/person',
    component: person,
    redirect: '/person/index',
    meta: {
        requireAuth: true,
        views: true,
        current: 4
    },
    children: [{
        name: '',
        path: '/person/index',
        component: personIndex,
        meta: {
            requireAuth: true,
            views: true,
            current: 4
        }
    },{
        name: '',
        path: '/person/edit',
        component: personEdit,
        meta: {
            requireAuth: true,
            views: true,
            current: 4,
            name: '编辑详情'
        }
    },{
        name: '',
        path: '/person/auth',
        component: personAuth,
        meta: {
            requireAuth: true,
            views: true,
            current: 4
        }
    },{
        name: '',
        path: '/person/list',
        component: personList,
        meta: {
            requireAuth: true,
            views: true,
            current: 4
        }
    },{
        name: '',
        path: '/person/manage',
        component: personManage,
        meta: {
            requireAuth: true,
            views: true,
            current: 4,
            name: '企业编辑'
        }
    },{
        name: '',
        path: '/person/editAuth',
        component: personEditauth,
        meta: {
            requireAuth: true,
            views: true,
            current: 4,
            name: '人员编辑'
        }
    }]
}]

/**
 *  @auth 返回的权限列表
 *  @routers  所有的权限
 */
const getAuth = function(auth){
    if(auth == 'root'){
        routers.push()
        return routers;
    }
    return utils.editAuth(auth, routers)
} 
export default getAuth