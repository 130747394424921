<template>
  <div class="content">
    <div class="filter_box">
      <div class="filter_left">
        <router-link
          v-if="roles.includes('edit')"
          target="_blank"
          :to="'/person/edit?level=' + level"
          class="orange_btn"
          >新增企业</router-link
        >
        <input type="text" placeholder="联系人名称" v-model="name" @keydown.enter="filter" />
        <button class="btn" @click="filter">筛选</button>
      </div>
      <!-- <button class="export" @click="filter">导出</button> -->
    </div>
    <div class="table_box">
      <div class="level_box">
        <div
          :class="['level_cell', item.value == level ? 'active' : '']"
          :data-index="index"
          v-for="(item, index) in levelList"
          :key="index"
          @click="chooseLevel"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="table_content">
        <el-table
          :data="tableData"
          style="width: 99.9% !important;"
          :stripe="true"
          ref="table"
        >
          <el-table-column prop="id" label="id" width="80"></el-table-column>
          <el-table-column prop="name" label="名称" width="120"></el-table-column>
          <el-table-column v-if="level > 2" :prop="level == 3 ? 'companyName' : 'tenementName'" label="一级所属" width="120"></el-table-column>
          <el-table-column v-if="level == 4" prop="companyName" label="二级所属" width="120"></el-table-column>
          <el-table-column prop="identity" label="身份"></el-table-column>
          <el-table-column prop="contactPerson" label="联系人">
          </el-table-column>
          <el-table-column prop="contactMobile" label="联系方式">
          </el-table-column>
          <el-table-column label="状态" width="150" v-if="roles.includes('update')">
            <template slot-scope="scope">
              <el-switch
                @change="changeStatus(scope.$index)"
                v-model="scope.row.status"
                active-color="#13ce66"
                inactive-color="#f00"
                active-text="启用"
                inactive-text="禁用"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" v-if="roles.length > 0">
            <template slot-scope="scope">
              <div class="options">
                <router-link
                  :to="'/person/edit?type=edit&level=' + level + '&id=' + scope.row.id"
                  class="btn gery"
                  v-if="roles.includes('edit')"
                  >编辑</router-link
                >
                <router-link
                  :to="'/person/edit?type=check&level=' + level + '&id=' + scope.row.id"
                  class="btn blue"
                  v-if="roles.includes('view')"
                  >查看</router-link
                >
                <button
                  class="btn red"
                  :data-index="scope.$index"
                  @click="delItem"
                  v-if="roles.includes('delete')"
                >
                  删除
                </button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="loading" v-if="loading">
        <i class="el-icon-loading"></i>
      </div>
    </div>
    <div class="page_box">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="total, prev, pager, next, jumper"
        background
        :total="totalList"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      name: "",
      level: 2,
      loading: true,
      levelList: [
        {
          title: "一级",
          value: 2,
        },
        {
          title: "二级",
          value: 3,
        },
        {
          title: "三级",
          value: 4,
        },
      ],
      tableData: [],
      currentPage: 1,
      rows: 10,
      totalList: 0,
      roles: ['view','delete','edit','update']
    };
  },
  watch: {
    $route: function(){
      this.checkRoute();
    }
  },
  mounted() {
    this.checkRoute();
    this.getList();
  },
  beforeUpdate(){
    this.$nextTick(() => { //在数据加载完，重新渲染表格
        this.$refs['table'].doLayout();
    })
  },
  methods: {
    filter() {
      this.currentPage = 1;
      this.getList();
    },
    chooseLevel(e) {
      const index = e.currentTarget.dataset.index;
      this.level = this.levelList[index].value;
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.currentPage = e
      this.getList();
    },
    // 修改用户状态
    changeStatus(e) {
      const id = this.tableData[e].id;
      const status = this.tableData[e].status;
      this.$api.admin
        .enableOrDisablePersonnel({
          id,
          status,
        })
        .then(
          () => {
            this.$message.success("修改成功");
          },
          (error) => {
            this.$message.error(error.msg);
          }
        );
    },
    delItem(e) {
      const index = e.currentTarget.dataset.index;
      const id = this.tableData[index].id;
      this.$confirm("是否确定删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.admin
            .deletePersonnel({
              id,
            })
            .then(
              () => {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.tableData.splice(index, 1);
              },
              (error) => {
                this.$message.error(error.msg);
              }
            );
        })
        .catch(() => {});
    },
    getList() {
      const page = this.currentPage;
      const rows = this.rows;
      const name = this.name;
      let data = {
        page,
        rows,
        level: this.level,
      };
      if (name) {
        data.contactPerson = name;
      }
      this.loading = true;
      this.$api.admin.getPersonnelList(data).then(
        (res) => {
          this.$nextTick(() => {
            if (page == 1) {
              this.totalList = res.data.total;
            }
            this.loading = false;
            this.tableData = res.data.rows;
          });
        },
        (error) => {
          this.loading = false;
          this.$message.error(error.msg);
        }
      );
    },
    checkRoute(){
      let rawAuth = this.$store.state.rawAuth;
      if(rawAuth == 'root'){
        return 
      }
      let roles = this.$route.meta.roles;
      let arr = []
      if(roles && roles.length > 0){
         roles.forEach(e => {
           arr.push(e.operating);
         })
      }
      this.roles = arr;
    }
  },
};
</script>

<style lang="less" scoped>
@import url("../../../../assets/less/pc/person/index.less");
</style>