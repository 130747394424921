<template>
  <div class="content">
    <div class="filter_box">
      <div class="filter_day">
        <div
          :class="['day', item.id == day ? 'active' : '']"
          :data-index="index"
          v-for="(item, index) in dayList"
          :key="index"
          @click="chooseDay"
        >
          {{ item.title }}
        </div>
      </div>
      <el-date-picker
        v-model="range"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        :picker-options="picker"
      >
      </el-date-picker>
      <button class="submit">筛选</button>
    </div>
    <div class="data_box">
      <div class="data_cell">
        <p class="data_num">26,321</p>
        <p class="data_tips">分享人数</p>
      </div>
      <div class="data_cell">
        <p class="data_num">26,321</p>
        <p class="data_tips">分享次数</p>
      </div>
      <div class="data_cell">
        <p class="data_num">26,321</p>
        <p class="data_tips">分享新增用户</p>
      </div>
    </div>
    <div class="chart_box">
      <p class="chart_title">实时分享人数</p>
      <div class="chart">
        <div id="line_chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "index",
  data() {
    return {
      day: 0,
      dayList: [
        {
          title: "昨天",
          id: 0,
        },
        {
          title: "近7日",
          id: 7,
        },
        {
          title: "近30日",
          id: 30,
        },
        {
          title: "近90日",
          id: 90,
        },
      ],
      range: "",
      picker: {
        disabledDate: (value) => {
          if (new Date(value).getTime() > new Date().getTime()) {
            return true;
          }
          return false;
        },
      },
    };
  },
  mounted() {
    let time = [];
    let flute = [];
    for (let i = 0; i < 24; i++) {
      let random = Math.floor(Math.random() * 100);
      if (i < 10) {
        i = "0" + i;
      }
      time.push(i + ":00");
      flute.push(random);
    }
    let data = {
      time,
      flute,
    };
    this.initChart("line_chart", data);
  },
  methods: {
    chooseDay(e) {
      const index = e.currentTarget.dataset.index;
      this.day = this.dayList[index].id;
    },
    initChart(params, data) {
      var myChart = echarts.init(document.getElementById(params));
      myChart.setOption({
        grid: {
          bottom: "5%",
          top: "5%",
          right: "2%",
        },
        color: ["#FF7930"],
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            color: "#6C7293",
          },
          data: data.time,
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: "#F5F5F5",
            },
          },
          axisLabel: {
            fontSize: 12,
            color: "#6C7293",
          },
          type: "value",
        },
        series: [
          {
            name: "访问量",
            type: "line",
            data: data.flute,
            showSymbol: false,
            barWidth: 20,
            itemStyle: {
              barBorderRadius: [10, 10, 0, 0],
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#FF7930", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(255,255,255,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../../../assets/less/pc/share/index.less");
</style>