<template>
  <div class="share">
      <div class="container">
          <router-view></router-view>
      </div>
  </div>
</template>

<script>
export default {
    name: 'share'
}
</script>

<style lang="less" scoped>
.share{
    padding: 80px 0 0 229px;
    min-width: 960px;
    .container{
        background: #F9F9FC;
        padding: 20px;
        height: calc(100vh - 120px);
        overflow-y: auto;
    }
}
</style>