<template>
  <div class="content">
    <transition name="el-fade-in-linear">
      <div class="data_account" v-show="num > 0">
        <div
          class="account_cell"
          v-for="(item, index) in accountList"
          :key="index"
        >
          <div class="left_data">
            <p class="value" v-if="item.value">{{ reduceNum(item.value) }}</p>
            <p class="lay_out" v-else></p>
            <p class="tips">{{ item.title }}</p>
          </div>
          <img :src="item.icon" alt="icon" class="icon" />
        </div>
      </div>
    </transition>

    <div class="center_data">
      <div class="center_left">
        <transition name="el-fade-in-linear">
          <div class="total_box" v-show="num > 1">
            <div class="total_title">
              <h5 class="title">累计订单数据</h5>
              <div class="total_day">
                <p
                  :class="['day', item.id == totalDay ? 'active' : '']"
                  v-for="(item, index) in totalDayList"
                  :key="index"
                  :data-index="index"
                  @click="chooseTotalday"
                >
                  {{ item.title }}
                </p>
              </div>
            </div>
            <div class="total_list">
              <div class="total_cell">
                <p class="cell_tips">
                  交易笔数
                  <span :class="totalDayData.count > 100000 ? 'small' : ''">{{
                    reduceNum(totalDayData.count || 0)
                  }}</span>
                </p>
                <div v-if="totalDay == 'TODAY'" class="progress blue">
                  <div
                    class="bar"
                    :style="{ width: totalDayData.countPercent + '%' }"
                  ></div>
                </div>
                <p class="cell_percentage">
                  日均交易笔数 {{ parseInt(totalDayData.dayCount) || 0 }}
                </p>
              </div>
              <div class="total_cell">
                <p class="cell_tips">
                  交易额
                  <span :class="totalDayData.price > 100000 ? 'small' : ''">{{
                    reduceNum(totalDayData.price || 0)
                  }}</span>
                </p>
                <div v-if="totalDay == 'TODAY'" class="progress green">
                  <div
                    class="bar"
                    :style="{ width: totalDayData.totalPercent + '%' }"
                  ></div>
                </div>
                <p class="cell_percentage">
                  日均交易额 {{ reduceNum(totalDayData.dayTotal || 0) }}
                </p>
              </div>
              <div class="total_cell">
                <p class="cell_tips">
                  客单价
                  <span :class="totalDayData.single > 100000 ? 'small' : ''">{{
                    reduceNum(totalDayData.single || 0)
                  }}</span>
                </p>
                <div v-if="totalDay == 'TODAY'" class="progress yellow">
                  <div
                    class="bar"
                    :style="{ width: totalDayData.singlePercent + '%' }"
                  ></div>
                </div>
                <p class="cell_percentage">
                  日均客单价 {{ reduceNum(totalDayData.daySingle || 0) }}
                </p>
              </div>
            </div>
          </div>
        </transition>
        <transition name="el-fade-in-linear">
          <div class="order_chart" v-show="num > 3">
            <div class="total_title">
              <h5 class="title">物业订单统计</h5>
              <div class="total_day">
                <p
                  :class="['day', item.id == orderDay ? 'active' : '']"
                  v-for="(item, index) in orderDayList"
                  :key="index"
                  :data-index="index"
                  @click="chooseOrderday"
                >
                  {{ item.title }}
                </p>
              </div>
            </div>
            <div class="chart_box">
              <div id="lineChart"></div>
              <div class="loading" v-if="!showLine">
                <i class="el-icon-loading"></i>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="center_right">
        <transition name="el-fade-in-linear">
          <div class="flutter_data" v-show="num > 2">
            <div class="title_box">
              <p class="title">流量数据(近7日)</p>
            </div>
            <div id="flutterLine"></div>
          </div>
        </transition>
        <transition name="el-fade-in-linear">
          <div class="total_account" v-show="num > 3">
            <div class="title_box">
              <p class="title">累计数据({{ year }}起至今)</p>
            </div>
            <div class="account_list">
              <div
                class="account_cell"
                v-for="(item, index) in totalAccount"
                :key="index"
              >
                <img :src="item.icon" alt="icon" class="icon" />
                <div class="cell_right">
                  <p class="cell_num" v-if="item.value">{{ reduceNum(item.value) }}</p>
                  <p class="lay_out" v-else></p>
                  <p class="cell_tips">{{ item.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "datapage",
  data() {
    return {
      accountList: [
        {
          title: "交易总额(元)",
          value: 0,
          icon: require("@/assets/icons/pc_index_01.png"),
          label: "totalTransaction",
          id: 1,
        },
        {
          title: "交易笔数(单)",
          value: 0,
          icon: require("@/assets/icons/pc_index_02.png"),
          label: "totalTransactionCount",
          id: 2,
        },
        {
          title: "团长人数(人)",
          value: 0,
          icon: require("@/assets/icons/pc_index_03.png"),
          label: "leaderCount",
          id: 3,
        },
        {
          title: "团长总交易额(元)",
          value: 0,
          icon: require("@/assets/icons/pc_index_04.png"),
          label: "leaderTotalTransaction",
          id: 4,
        },
        {
          title: "团长总交易单数(单)",
          value: 0,
          icon: require("@/assets/icons/pc_index_05.png"),
          label: "leaderTotalTransactionCount",
          id: 5,
        },
      ],
      totalDay: "TODAY",
      totalDayList: [
        {
          title: "今日",
          id: "TODAY",
        },
        // {
        //   title: "昨日",
        //   id: 'YESTERDAY',
        // },
        {
          title: "近7天",
          id: "WEEK",
        },
        {
          title: "近30天",
          id: "MONTH",
        },
      ],
      totalDayData: {},
      orderDay: "TODAY",
      orderDayList: [
        {
          title: "今日",
          id: "TODAY",
        },
        // {
        //   title: "昨日",
        //   id: 'YESTERDAY',
        // },
        {
          title: "近7天",
          id: "WEEK",
        },
        {
          title: "近30天",
          id: "MONTH",
        },
      ],
      totalAccount: [
        {
          icon: require("@/assets/icons/pc_index_06.png"),
          title: "总用户数",
          value: "",
          label: "userCount",
          id: 0,
        },
        {
          icon: require("@/assets/icons/pc_index_07.png"),
          title: "总交易额",
          value: "",
          label: "totalAnnualTransactionVolume",
          id: 1,
        },
        {
          icon: require("@/assets/icons/pc_index_08.png"),
          title: "总交易数",
          value: "",
          label: "totalAnnualTransactions",
          id: 2,
        },
      ],
      showLine: false,
      date: "",
      num: 0,
    };
  },
  created() {
    this.year = new Date().getFullYear() + ".1.1";
  },
  mounted() {
    this.timeAdd();
    this.getData();
    this.getTotal();
  },
  methods: {
    timeAdd() {
      let num = this.num;
      let timer = null;
      if (num < 5) {
        timer = setTimeout(() => {
          num += 1;
          this.num = num;
          if (num > 3) {
            this.initLine("flutterLine");
            if (num > 4) {
              this.getProperty();
            }
          }
          this.timeAdd();
        }, 200);
      } else {
        clearTimeout(timer);
      }
    },
    // 数字计算
    reduceNum: function (params) {
      let over = false;
      if(params > 10000){
        params = params / 10000;
        over = true;
      }
      params = Number(params).toFixed(2);
      params = params.toString().split(".");
      if (params[1] && params[1] > 0) {
        if (Number(params[1]) > 9) {
          params = this.filterZero(params)
        } else if (Number(params[1]) > 0 && Number(params[1]) < 9) {
          params = this.filterZero(params)
        }
      } else {
        params = params[0];
      }
      if (over) {
        return params + "W+";
      } else {
        return params;
      }
    },
    filterZero(params){
      if(String(params[1]).length > 1){
        let str = String(params[1]).split('');
        if(Number(str[1]) > 0){
          return Number(params[0] + "." + params[1]).toFixed(2);
        }else{
          return Number(params[0] + "." + params[1]).toFixed(1);
        }
      }else{
        return Number(params[0] + "." + params[1]).toFixed(1);
      }
    },
    // 选择累计时间
    chooseTotalday(e) {
      const index = e.currentTarget.dataset.index;
      this.totalDay = this.totalDayList[index].id;
      this.getTotal();
    },
    // 选择订单统计时间
    chooseOrderday(e) {
      const index = e.currentTarget.dataset.index;
      this.orderDay = this.orderDayList[index].id;
      this.getProperty();
    },
    // 柱状+折线图
    initData(params, data) {
      var myChart = echarts.init(document.getElementById(params));
      // 绘制图表
      myChart.setOption({
        grid: {
          bottom: "10%",
          top: "15%",
        },
        color: ["#23B899", "#F4DF58", "#F3F3FB"],
        legend: {
          show: true,
          icon: "circle",
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            fontSize: 10,
          },
          data: [
            {
              name: "订单交易额",
              textStyle: {
                color: "#6C7293",
              },
            },
            {
              name: "订单交易数",
              textStyle: {
                color: "#6C7293",
              },
            },
            {
              name: "",
              textStyle: {
                color: "#6C7293",
              },
            },
          ],
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            color: "#6C7293",
            fontSize: 20,
          },
          axisLabel: {
            fontSize: 12,
            color: "#666",
          },
          data: data.propertyName,
        },
        yAxis: [
          {
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: "#F5F5F5",
              },
            },
            axisLabel: {
              fontSize: 12,
              color: "#6C7293",
            },
            nameTextStyle: {
              color: "#6C7293",
              fontSize: 12,
            },
            type: "value",
            scale: true,
            name: "订单数",
          },
          {
            axisLine: {
              show: false,
            },
            axisLabel: {
              fontSize: 12,
              color: "#666",
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: "#F5F5F5",
              },
            },
            nameTextStyle: {
              color: "#666",
              fontSize: 12,
            },
            type: "value",
            scale: true,
            name: "订单额",
          },
        ],
        series: [
          {
            name: "订单交易额",
            type: "line",
            yAxisIndex: 0,
            data: data.totalPrice,
          },
          {
            name: "订单交易数",
            type: "line",
            yAxisIndex: 0,
            data: data.orderCount,
          },
          {
            name: "",
            type: "bar",
            yAxisIndex: 1,
            barWidth: 20,
            emphasis: {
              itemStyle: {
                color: "#F3F3FB",
              },
            },
            tooltip: {
              show: false
            },
            data: data.totalPrice,
          },
        ],
      },true);
    },
    // 折线图
    initLine(params) {
      var myChart = echarts.init(document.getElementById(params));
      // 绘制图表
      myChart.setOption({
        grid: {
          bottom: "10%",
          top: "10%",
          right: "5%",
        },
        color: ["#58CFFF", "#F4DF58"],
        legend: {
          show: true,
          icon: "circle",
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            fontSize: 10,
          },
          right: "5%",
          data: [
            {
              name: "访问用户",
              textStyle: {
                color: "#6C7293",
              },
            },
            {
              name: "新增用户",
              textStyle: {
                color: "#6C7293",
              },
            },
          ],
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            color: "#666",
            fontSize: 20,
          },
          axisLabel: {
            fontSize: 12,
            color: "#666",
            margin: 20,
          },
          data: [
            "2020-07-16",
            "2020-07-17",
            "2020-07-18",
            "2020-07-19",
            "2020-07-20",
            "2020-07-21",
            "2020-07-22",
          ],
        },
        yAxis: [
          {
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: "#F5F5F5",
              },
            },
            axisLabel: {
              fontSize: 12,
              color: "#6C7293",
            },
            nameTextStyle: {
              color: "#6C7293",
              fontSize: 12,
            },
            type: "value",
            scale: true,
          },
        ],
        series: [
          {
            name: "访问用户",
            type: "line",
            data: [40, 50, 60, 35, 55, 95, 105],
          },
          {
            name: "新增用户",
            type: "line",
            data: [10, 20, 30, 25, 40, 65, 55],
          },
        ],
      },true);
    },
    // 获取数据
    getData() {
      const userData = JSON.parse(this.$store.state.userData);
      const bindId = userData.bindId;
      const level = userData.level;
      this.$api.admin
        .getHomepageUnconditionalStatistics({
          bindId,
          level,
        })
        .then((res) => {
          let data = res.data;
          let accountList = this.accountList;
          let totalAccount = this.totalAccount;
          this.accountList = this.compare(accountList, data.headerStatistics);
          this.totalAccount = this.compare(totalAccount, data.yearStatistics);
        });
    },
    compare(arr1, obj2) {
      let arr = [];
      for (let key in obj2) {
        let result = arr1.find((item) => {
          return item.label == key;
        });
        result.value = obj2[key];
        arr.push(result);
      }
      arr.sort(this.sortNumber);
      return arr;
    },
    sortNumber(a, b) {
      return a.id - b.id;
    },
    // 获取累计订单数据
    getTotal() {
      this.$api.admin
        .getGrandTotalOrderStatistics({
          grandTotalOrderType: this.totalDay,
        })
        .then(
          (res) => {
            // 客单价 = 交易额 / 交易笔数
            const type = this.totalDay;
            let data = res.data;
            if (type == "TODAY") {
              for (let key in data) {
                if (key != "YESTERDAY") {
                  data.count = data[key].ordersCount;
                  data.price = data[key].ordersAmount;
                  let single = (data.price / data.count).toFixed(2);
                  data.single = isNaN(single) ? 0 : single;
                } else {
                  data.dayCount = data[key].ordersCount;
                  data.dayTotal = data[key].ordersAmount;
                  let daySingle = (data.dayTotal / data.dayCount).toFixed(2);
                  data.daySingle = isNaN(daySingle) ? 0 : daySingle;
                }
                if (typeof data[key] == "object") {
                  delete data[key];
                }
              }
            } else {
              let single = (data.price / data.count).toFixed(2);
              data.single = isNaN(single) ? 0 : single;
              if (type == "WEEK") {
                data.dayCount = (data.count / 7).toFixed(2);
                data.dayTotal = (data.price / 7).toFixed(2);
                data.daySingle = (data.single / 7).toFixed(2);
              } else if (type == "MONTH") {
                data.dayCount = (data.count / 30).toFixed(2);
                data.dayTotal = (data.price / 30).toFixed(2);
                data.daySingle = (data.single / 30).toFixed(2);
              }
            }
            data.countPercent = this.calculatePercent(
              data.count,
              data.dayCount
            );
            data.totalPercent = this.calculatePercent(
              data.price,
              data.dayTotal
            );
            data.singlePercent = this.calculatePercent(
              data.single,
              data.daySingle
            );
            this.totalDayData = data || {};
          },
          (error) => {
            this.$message.error(error.msg);
          }
        );
    },
    // 判断是否数字
    calculatePercent(params, oldData) {
      oldData = Number(oldData);
      let result = 0;
      if (params == oldData || (oldData == undefined && params == 0)) {
        return 80;
      }
      if(oldData > 0){
        result = parseFloat(params / (oldData * 0.2 + oldData));
      }else{
        result = 1;
      }
      if (typeof result == "number") {
        result = result * 100;
        if (result > 100) {
          result = 100;
        }
        return result;
      } else {
        return (result = 100);
      }
    },
    // 获取物业订单统计
    getProperty() {
      this.$api.admin
        .getPropertyOrderStatistics({
          propertyOrderStatisticsType: this.orderDay,
        })
        .then(
          (res) => {
            let arr = res.data;
            let data = {
              propertyName: [],
              totalPrice: [],
              orderCount: [],
            };
            arr.forEach((e) => {
              data.propertyName.push(e.name);
              data.totalPrice.push(e.totalPrice || 0);
              data.orderCount.push(e.orderCount || 0);
            });
            this.showLine = true;
            this.initData("lineChart", data);
          },
          (error) => {
            this.$message.error(error.msg);
          }
        );
    },
  },
};
</script>

<style scoped lang="less">
@import url("../../../../assets/less/pc/index/index.less");
</style>