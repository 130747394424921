<template>
  <div class="header">
    <div class="logo_box">
      <img :src="logo" alt="logo" class="logo" />
      <p class="project_name">餸鲜生数据助手</p>
    </div>
    <div class="header_right">
      <div class="tab_box">
        <template v-for="(item, index) in list">
          <div
            :class="['tab_cell', current == item.current ? 'active' : '']"
            v-if="item.hasRole"
            :key="index"
            :data-index="index"
            @click="chooseTab"
          >
            {{ item.title }}
          </div>
        </template>
        
      </div>
      <div class="member_data">
        <div class="data_box" @click="adminOption">
          <p class="member_name">{{ member.contactPerson }}</p>
          <i class="el-icon-arrow-down icon"></i>
        </div>
        <div class="login_option" v-if="showOption">
          <p class="option_cell" @click="resetPassword">修改密码</p>
          <p class="option_cell" @click="loginOut">退出登录</p>
        </div>
      </div>
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            type="password"
            v-model="form.oldPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input
            type="password"
            v-model="form.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input
            type="password"
            v-model="form.confirmPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitReset('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import getAuth from '../../routers/allRouter';
export default {
  name: "sheader",
  data() {
    return {
      logo: require("@/assets/logo/logo_web.png"),
      list: [
        {
          title: "系统首页",
          link: "/",
          hasRole: false,
          current: 0
        },
        {
          title: "流量分析",
          link: "/analyze",
          hasRole: false,
          current: 1
        },
        {
          title: "订单分析",
          link: "/order",
          hasRole: false,
          current: 2
        },
        {
          title: "分享传播",
          link: "/share",
          hasRole: false,
          current: 3
        },
        {
          title: "人员管理",
          link: "/person",
          hasRole: false,
          current: 4
        },
      ],
      current: -1,
      member: {},
      showOption: false,
      dialogFormVisible: false,
      form: {
        password: "",
        confirmPassword: "",
        oldPassword: "",
      },
      rules: {
        password: {
          required: true,
          message: "新密码不能为空",
          trigger: "blur",
        },
        confirmPassword: {
          required: true,
          message: "确认密码不能为空",
          trigger: "blur",
        },
        oldPassword: {
          required: true,
          message: "旧密码不能为空",
          trigger: "blur",
        },
      },
    };
  },
  watch: {
    $route: function (to, from) {
      if(to.path == '/403'){
        this.current = from.meta.current;
      }else{
        this.current = to.meta.current;
      }
    }
  },
  created() {
    let meta = this.$route.meta.current;
    this.current = meta || "";
    this.member = JSON.parse(this.$store.state.userData) || {}
    this.checkRoute();
  },
  methods: {
    // 账户操作
    adminOption() {
      this.showOption = !this.showOption;
    },
    // 退出登录
    loginOut() {
      this.$confirm("是否确定退出登录", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(
          () => {
            const loading = this.$loading({
                lock: true,
                text: '正在退出登录',
                spinner: 'el-icon-loading',
                background: 'rgba(255,255,255, 0.2)'
            });
            this.$api
              .userLogout({
                validation: this.$store.state.token,
              })
              .then(() => {
                loading.close();
                this.$store.commit("updataToken", "");
                this.$store.commit("updateNoauth", 0);
                this.$router.replace("/login");
                this.$message.success("退出成功");
              },error => {
                loading.close();
                this.$message.error(error.msg);
              });
          })
        .catch(() => {
          this.showOption = false;
        });
    },
    // 选择页面
    chooseTab(e) {
      const index = e.currentTarget.dataset.index;
      this.current = index;
      const path = this.$route.path;
      const link = this.list[index].link;
      if (path == link) {
        return false;
      }
      this.$router.push({
        path: link,
      });
    },
    // 修改密码
    resetPassword() {
      this.dialogFormVisible = true;
      this.showOption = false;
    },
    // 提交修改密码
    submitReset(formName) {
      const form = this.form;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (form.password != form.confirmPassword) {
            this.$message.error("两次输入密码不一致");
            return;
          }
          this.$api
            .updatePassword({
              validation: this.$store.state.token,
              oldPassword: form.oldPassword,
              newPassword: form.newPassword,
            })
            .then(
              () => {
                this.$message.success("修改成功, 请重新登录");
                this.$store.commit("updataToken", "");
                this.$router.replace("/login");
              },
              (error) => {
                this.$message.error(error.msg);
              }
            );
        }
      });
    },
    // 检出路由
    checkRoute(){
      const rawAuth = this.$store.state.rawAuth || '[]';
      if(!rawAuth){
        return
      }
      let auth = getAuth(rawAuth);
      let arr = [];
      auth.forEach(e => {
        let result = this.filter(e);
        // 这里, 将来要改
        if(result && result.link != '/share' && result.link != '/analyze'){
          // 拿出拥有的路由
          result.link = e.children[0].path;
          result.hasRole = true;
          arr.push(result)
        }
      });
      this.list = arr;
    },
    filter(params){
      const list = this.list;
      const result = list.find(item => {
        return item.link == params.path
      });
      return result;
    }
  },
};
</script>

<style scoped lang="less">
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background: #fff;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  min-width: 960px;
  .logo_box {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    .logo {
      width: 40px;
      height: 40px;
      margin-right: 18px;
    }
    .project_name {
      font-size: 18px;
      color: #333;
    }
  }
  .header_right {
    flex: 0.8;
    padding-right: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tab_box {
      display: flex;
      align-items: center;
      .tab_cell {
        padding-right: 58px;
        font-size: 18px;
        color: #333;
        cursor: pointer;
      }
      .active {
        color: #ff7930;
      }
      .tab_cell:hover {
        color: #ff7930;
      }
    }
    .member_data {
      position: relative;
      cursor: pointer;
      height: 80px;
      display: flex;
      align-items: center;
      .data_box {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .member_name {
          font-size: 18px;
          color: #333;
        }
        .icon {
          font-size: 14px;
          color: #aeaeae;
          margin-top: 4px;
          margin-left: 4px;
        }
      }
      .login_option {
        position: absolute;
        top: 80px;
        right: -10px;
        width: 100px;
        background: #fff;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
        .option_cell {
          padding: 10px 0;
          font-size: 12px;
          color: #666;
          text-align: center;
        }
        .option_cell:hover {
          background: #fff1e9;
        }
      }
    }
  }
}
</style>