<template>
  <div class="content">
    <div class="form_box">
      <div class="form_cell">
        <p class="form_tips">公司名称</p>
        <div class="from_content">
          <el-select
            v-model="form.company"
            placeholder="请选择"
            @change="chooseFirst"
            :disabled="type == 'check'"
          >
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="form_cell" v-if="level > 2">
        <p class="form_tips">一级所属</p>
        <div class="from_content">
          <el-select
            @change="chooseSecond"
            v-model="form.secondcompany"
            placeholder="请选择"
            :disabled="type == 'check' || form.company == ''"
          >
            <el-option
              v-for="item in secondcompanyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="form_cell" v-if="level == 4">
        <p class="form_tips">二级所属</p>
        <div class="from_content">
          <el-select
            v-model="form.property"
            placeholder="请选择"
            :disabled="
              type == 'check' || form.company == '' || form.secondcompany == ''
            "
          >
            <el-option
              v-for="item in propertyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="form_cell">
        <p class="form_tips">身份</p>
        <div class="from_content">
          <el-select
            @change="chooseIdentify"
            v-model="form.identify"
            placeholder="请选择"
            :disabled="type == 'check'"
          >
            <el-option
              v-for="item in identiList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="form_cell">
        <p class="form_tips">联系人</p>
        <div class="form_content">
          <input
            type="text"
            v-model="form.linkPerson"
            placeholder="请输入"
            :readonly="type == 'check'"
          />
        </div>
      </div>
      <div class="form_cell">
        <p class="form_tips">联系方式</p>
        <div class="form_content">
          <input
            type="text"
            v-model="form.linkPhone"
            placeholder="请输入"
            :readonly="type == 'check'"
            maxlength="11"
          />
        </div>
      </div>
      <div class="form_cell" v-if="type != 'check'">
        <p class="form_tips">密码</p>
        <div class="form_content">
          <input
            type="password"
            v-model="form.password"
            placeholder="请输入"
            :readonly="type == 'check'"
            autocomplete="new-password"
          />
          <button
            class="btn"
            :disabled="!form.password"
            @click="resetPassword"
            v-if="type != 'check'"
          >
            重置
          </button>
        </div>
      </div>
    </div>
    <div class="form_submit" v-if="type != 'check'">
      <button class="btn orange" @click="submit">保存</button>
      <button class="btn gery" @click="reback">取消</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "edit",
  data() {
    return {
      form: {
        company: "",
        identify: "",
        linkPerson: "",
        linkPhone: "",
        password: "",
        secondcompany: "",
        property: "",
      },
      companyList: [],
      secondcompanyList: [],
      propertyList: [],
      identiList: [
        {
          title: "公司",
          id: 2,
        },
        {
          title: "物业",
          id: 3,
        },
        {
          title: "楼盘/小区",
          id: 4,
        },
      ],
      type: "",
      level: "",
    };
  },
  created() {
    const query = this.$route.query;
    this.level = query.level;
    this.form.identify = parseInt(query.level);
    this.getPre();
    if (query.type) {
      this.type = query.type;
      if (query.type == "check" || query.type == "edit") {
        this.id = query.id;
      }
    }
  },
  methods: {
    // 重置密码
    resetPassword() {
      this.form.password = "";
    },
    // 选择身份
    chooseIdentify(e) {
      if(e == 2){
        this.form.secondcompany = '';
        this.form.property = '';
      } else if(e == 3){
        this.form.property = '';
      }
      this.level = e;
    },
    // 保存
    submit() {
      const form = this.form;
      const id = this.id;
      if (form.company == "") {
        this.$message.error("请选择公司名称");
        return;
      }
      if (form.identify == "") {
        this.$message.error("请选择身份");
        return;
      }
      if (form.linkPerson == "") {
        this.$message.error("请输入联系人");
        return;
      }
      if (form.linkPhone == "") {
        this.$message.error("请输入联系方式");
        return;
      }
      if (form.password == "") {
        this.$message.error("请输入密码");
        return;
      }
      let level = this.level;
      let companyId = "";
      if (level == 2) {
        companyId = form.company;
      } else if (level == 3) {
        companyId = form.secondcompany;
      } else if (level == 4) {
        companyId = form.property;
      }
      let data = {
        companyId,
        level: this.level,
        identity: form.identify,
        contactPerson: form.linkPerson,
        contactMobile: form.linkPhone,
        password: form.password,
      };
      if(id){
        data.id = id;
      }
      this.$api.admin.addOrModifyPersonnelInfo(data).then(
        () => {
          this.$message.success("保存成功");
          this.$router.push("/person/index");
        },
        (error) => {
          this.$message.error(error.msg);
        }
      );
    },
    reback() {
      this.$router.back();
    },
    // 选择一级公司
    chooseFirst(e) {
      const list = this.companyList;
      const result = list.find((item) => {
        return item.id == e;
      });
      this.secondcompanyList = result.childList || [];
      this.form.secondcompany = "";
      this.form.property = "";
    },
    // 选择二级公司
    chooseSecond(e) {
      const list = this.secondcompanyList;
      const result = list.find((item) => {
        return item.id == e;
      });
      this.propertyList = result.childList || [];
      this.form.property = "";
    },
    // 获取公司
    getPre() {
      this.$api.admin
        .getCompanyDropDownList({
          level: 2,
        })
        .then(
          (res) => {
            this.companyList = res.data;
            if(this.id){
              this.getDetail()
            }
          },
          (error) => {
            this.$message.error(error.msg);
          }
        );
    },
    getDetail(){
      const id = this.id;
      const loading = this.$loading({
          lock: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(255,255,255, 0.2)'
      });
      this.$api.admin
        .getPersonnelDetails({
          id
        })
        .then(
          (res) => {
            loading.close();
            const data = res.data;
            this.form = {
              linkPerson: data.contactPerson,
              linkPhone: data.contactMobile,
              company: data.level == 4 ? parseInt(data.tenementId) : data.level == 3 ? parseInt(data.companyId) : parseInt(data.bindId),
              password: "",
              identify: data.level,
              secondcompany: data.level == 3 ? parseInt(data.bindId) : parseInt(data.companyId) || '',
              property: data.level == 4 ? parseInt(data.bindId) || '' : ''
            }
            this.level = data.level;
            let secondcompanyList = [];
            if(data.level == 2){
               this.secondcompanyList = this.companyList.find(item => {
                 return item.id == parseInt(data.bindId)
              }).childList;
            }else if(data.level == 3){
              secondcompanyList = this.companyList.find(item => {
                 return item.id == parseInt(data.companyId)
              });
              this.secondcompanyList = secondcompanyList.childList;
              this.propertyList = secondcompanyList.childList.find(item => {
                return item.id == parseInt(data.bindId);
              }).childList
            }else if(data.level == 4){
              secondcompanyList = this.companyList.find(item => {
                 return item.id == parseInt(data.tenementId)
              });
              let thirdList = secondcompanyList.childList.find(item => {
                return item.id == parseInt(data.companyId);
              })
              this.secondcompanyList = secondcompanyList.childList;
              this.propertyList = thirdList.childList;
            }
          },
          (error) => {
            loading.close();
            this.$message.error(error.msg);
          }
        ).catch(() => {
          loading.close();
        })
    }
  },
};
</script>

<style scoped lang="less">
@import url("../../../../assets/less/pc/person/edit.less");
.content {
  .form_cell {
    .form_tips {
      width: 120px;
    }
  }
}
</style>