<template>
  <div class="noFound">
      <img :src="icon" alt="404" class="icon" />
      <p class="tips">您的页面走丢了</p>
      <router-link to="/login" class="reback" replace>返回登录</router-link>
  </div>
</template>

<script>
export default {
    data () {
        return {
            icon: require('@/assets/error/404.png')
        }
    }
}
</script>

<style scoped lang="less">
.noFound{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    user-select: none;
    .icon{
        width: 1202px;
        height: 692px;
        -webkit-filter: grayscale(100%);
        object-fit: contain;
    }
    .tips{
        font-size: 24px;
        color: #333;
        filter: grayscale(40%);
    }
    @media screen and(max-width: 1024px){
        .icon{
            width: 90%;
        }
    }
    .reback{
        background: #ff7930;
        margin-top: 30px;
        border-radius: 30px;
        font-size: 24px;
        color: #fff;
        padding: 10px 30px;
    }
    .reback:hover{
        opacity: .5;
    }
    .reback:active{
        opacity: 1;
    }
}
</style>