import store from '../store/index';
import { Toast } from 'vant'
import router from '../routers/router'
// eslint-disable-next-line no-unused-vars
const get = (url, params, config = {}) => new Promise((resolve, reject) => {
    window.axios({
        method: 'get',
        url,
        params,
        ...config,
    }).then((response) => {
        if (response.data.status == 200) {
            resolve(response.data);
        } else if (response.data.status == 300) { //登录过期
            if (store.state.windowWidth < 1024) {
                // mobile 
                Toast.clear();
                store.commit('logout');
                router.replace('/login');
            }
        } else {
            if (store.state.windowWidth < 1024) {
                // mobile 
                Toast.clear();
                Toast({
                    type: 'fail',
                    icon: "",
                    message: response.data.msg
                })
            }
            reject(response.data);
        }
    }).catch((error) => {
        reject(error);
    });
});

const post = (url, params, config = {}) => new Promise((resolve, reject) => {
    window.axios({
        method: 'post',
        url,
        params,
        ...config
    }).then((response) => {
        if (response.data.status == 200) {
            resolve(response.data);
        } else if (response.data.status == 300) { //登录过期
            if (store.state.windowWidth < 1024) {
                // mobile 
                Toast.clear();
                store.commit('logout');
                router.replace('/login');
            }
        } else {
            if (store.state.windowWidth < 1024) {
                // mobile 
                Toast.clear();
                Toast({
                    type: 'fail',
                    icon: "",
                    message: response.data.msg
                })
            }
            reject(response.data);
        }
    }).catch((error) => {
        reject(error);
    })
})

const tokenParams = (url, params, method, config = {}) => new Promise((resolve, reject) => {
    params.token = store.state.token || '';
    window.axios({
        method: method || 'post',
        url,
        params,
        ...config
    }).then((response) => {
        if(!response.data){
            const msg = {
                msg: "未知错误"
            }
            reject(msg);
        }else{
            if (response.data.status == 200) {
                resolve(response.data);
            } else if(response.data.status != 403 && response.data.status != 400){
                reject(response.data);
            }
        }
    }).catch((error) => {
        reject(error);
    })
})

const api = {
    login: params => post('goLogin', params).then(res => res),
    updatePassword: params => post('updatePassword', params).then(res => res),
    userLogout: params => tokenParams('userLogout', params).then(res => res),
    getHomePageDataOverview: params => post('h5/statistics/getHomePageDataOverview', params).then(res => res),
    getLevelStatistics: params => post('h5/statistics/getLevelStatistics', params).then(res => res),
    statisticsByDay: params => post('h5/statistics/statisticsByDay', params).then(res => res),
    statisticsByMonth: params => post('h5/statistics/statisticsByMonth', params).then(res => res),
    statisticsByWeek: params => post('h5/statistics/statisticsByWeek', params).then(res => res),
    getUpLevelBindInfo: params => post('h5/statistics/getUpLevelBindInfo', params).then(res => res),
    getLeaderStatisticsByType: params => post('h5/statistics/getLeaderStatisticsByType', params).then(res => res),
    getLeaderStatistics: params => post('h5/statistics/getLeaderStatistics', params).then(res => res),
    admin: {
        // 首页数据
        getHomepageUnconditionalStatistics: params => tokenParams('back/home/getHomepageUnconditionalStatistics', params).then(res => res),
        getGrandTotalOrderStatistics: params => tokenParams('back/home/getGrandTotalOrderStatistics', params).then(res => res),
        getPropertyOrderStatistics: params => tokenParams('back/home/getPropertyOrderStatistics', params).then(res => res),
        // 公司管理
        getCompanyRolesDetails: params => tokenParams('back/company/getCompanyRolesDetails', params).then(res => res),
        addOrModifyCompanyInfo: params => tokenParams('back/company/addOrModifyCompanyInfo', params).then(res => res),
        getCompanyList: params => tokenParams('back/company/getCompanyList', params).then(res => res),
        getCompanyDetails: params => tokenParams('back/company/getCompanyDetails', params).then(res => res),
        getCompanyDropDownList: params => tokenParams('back/company/getCompanyDropDownList', params).then(res => res),
        deleteCompanyById: params => tokenParams('back/company/deleteCompanyById', params).then(res => res),
        // 人员管理
        getPersonnelList: params => tokenParams('back/personnel/getPersonnelList', params).then(res => res),
        getPersonnelDetails: params => tokenParams('back/personnel/getPersonnelDetails', params).then(res => res),
        enableOrDisablePersonnel: params => tokenParams('back/personnel/enableOrDisablePersonnel', params).then(res => res),
        deletePersonnel: params => tokenParams('back/personnel/deletePersonnel', params).then(res => res),
        addOrModifyPersonnelInfo: params => tokenParams('back/personnel/addOrModifyPersonnelInfo', params).then(res => res),
        // 订单分析
        getTransactionList: params => tokenParams('back/transaction/getTransactionList', params).then(res => res),
        getTransactionStatisticsByTimePeriod: params => tokenParams('back/transaction/getTransactionStatisticsByTimePeriod', params).then(res => res),
        getClassificationDropDown: params => tokenParams('back/class/getClassificationDropDown', params).then(res => res),
        getClassificationStatistics: params => tokenParams('back/class/getClassificationStatistics', params).then(res => res)
    }
}
export default api