// 节流
const throttle = function (fn) {
    let canRun = true
    return () => {
        if (!canRun) {
            return
        }
        // 设置canRun为false
        canRun = false;
        setTimeout(() => {
            // 最后在setTimeout执行完毕后再把标记设置为true(关键)表示可以执行下一次循环了。
            // 当定时器没有执行的时候标记永远是false，在开头被return掉
            fn.apply(this, arguments);
            canRun = true;
        }, 500);
    }
}

// 权限路由设置
const editAuth = function (auth, old) {
    let arr = [];
    auth = JSON.parse(auth).sort((a, b) => {
        return a.id - b.id
    });
    auth.forEach((e) => {
        if(e.roles){
            let result = filter(old,e, 0);
            // 如果能得出下级
            if(result){
                // 下级查找是否有数据
                let children = [];
                e.roles.forEach(item => {
                    let child = filter(result.children, item, 1);
                    // 将查找到的数据添加一个数组内
                    if(child){
                        child = compare(child, item);
                        children.push(child);
                        if(child.meta.roles){
                            const path = child.meta.roles.find(key => {
                                return key.routing;
                            });
                            if(path){
                                let thirdLink = filter(result.children, path,1);
                                children.push(thirdLink);
                            }
                        }
                    }
                });
                // 重置二级路由
                result.children = children;
                arr.push(result);
            }
        }
    });
    return arr;
}

const filter = function (old, params,level) {
    const list = old;
    let result = null;
    if(level == 0){
        result = list.find(item => {
            return item.path == params.routing
        })
    }else if(level == 1){
        result = list.find(item => {
            return item.path == params.routing
        })
    }
    return result;
}
// 对比两组数据
const compare = function(old, newObj){
    if(old.path == newObj.routing){
        if(newObj.roles){
            old.meta.roles = newObj.roles;
        } else {
            old.meta.noRoal = true;
        }
        return old;
    }else{
        return old
    }
}

export default {
    throttle,
    editAuth
}