<template>
  <div id="app">
    <keep-alive v-if="$route.meta.title">
      <SHeader></SHeader>
    </keep-alive>
    <!-- PC端的页头和侧栏 -->
    <template v-if="$route.meta.views">
      <keep-alive>
        <SPHeader></SPHeader>
      </keep-alive>
      <keep-alive>
        <SPNav></SPNav>
      </keep-alive>
    </template>
    <router-view></router-view>
    <keep-alive v-if="$route.meta.tab">
      <SMenu :current.sync="current"></SMenu>
    </keep-alive>
  </div>
</template>

<script>
import SMenu from "./components/menu";
import SHeader from "./components/header";
// 以下pc端
import SPHeader from "./components/PC/sheader";
import SPNav from "./components/PC/slidebar";
export default {
  name: "App",
  components: {
    SMenu,
    SHeader,
    SPHeader,
    SPNav
  },
  data() {
    return {
      current: String(this.$store.state.current),
      type: "",
      num: 1
    };
  },
  created() {
    this.routeOption();
  },
  mounted() {
    // 监听屏幕变化
    window.addEventListener('resize',this.$utils.throttle(this.bindresize))
  },
  methods: {
    bindresize(){
      const type = this.type;
      const width = window.innerWidth;
      this.num += 1;
      if (width <= 1024) {
        if (type == 1) {
          this.$createRouter();
          localStorage.clear();
          this.type = 2;
          location.reload();
        }
      }else{
        if(type == 2){
          this.$createRouter();
          localStorage.clear();
          this.type = 1;
          location.reload();
        }
      }
    },
    routeOption() {
      const screenWidth = window.innerWidth;
      this.$store.commit("refreshWindow", screenWidth);
      if (screenWidth > 1024) {
        this.type = 1;
      } else {
        this.type = 2;
        this.$store.commit("updataUserData", this.$common.getUserData());
        this.$store.commit("updateLevel", this.$common.getLevel());
        this.$store.commit("updataCompanyList", this.$common.getCompanyList());
        this.$store.commit(
          "updataPropertyList",
          this.$common.getPropertyList()
        );
      }
    },
  },
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  font-family: "PingFang SC";
  font-size: 14px;
}
html,
body {
  height: 100%;
  background: #fff;
}
input,
select,
textarea,
button {
  border: none;
  background: transparent;
  outline: none;
  -webkit-appearance: none;
  display: block;
}
em,
i {
  font-style: normal;
}

ul {
  padding-left: 0;
  li {
    list-style: none;
    padding: 0;
  }
}

b,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
}

input,
input[type="submit"],
input[type="reset"],
input[type="button"],
select {
  border-radius: 0;
  outline: 0;
}

p {
  margin: 0;
}

input:input-placeholder {
  font-size: 14px;
  color: #aeaeae;
}
textarea::-webkit-input-placeholder {
  font-size: 14px;
  color: #aeaeae;
}
button:disabled {
  font-size: 14px;
  color: #aeaeae;
}
input::-webkit-input-placeholder {
  font-size: 14px;
  color: #aeaeae;
}
input::-moz-placeholder {
  font-size: 14px;
  color: #aeaeae;
}
input:-ms-input-placeholder {
  font-size: 14px;
  color: #aeaeae;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}
img {
  display: block;
  object-fit: cover;
}
@media screen and(max-width:1024px) {
  * {
    font-size: 24px;
  }
}
.van-toast__text {
  font-size: 14px;
}
</style>
