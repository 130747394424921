<template>
    <div class="index">
        <div class="logo_box">
            <img :src="logo" alt="logo" class="logo" />
        </div>
        <div class="form_box">
            <div class="form_cell">
                <input type="text" placeholder="请输入手机号" maxlength="11" v-model="form.loginName" />
            </div>
            <div class="form_cell">
                <input type="password" placeholder="请输入密码" v-model="form.password" maxlength="18" />
            </div>
            <div class="form_cell">
              <div class="form_item">
                  <input type="text" placeholder="请输入验证码" v-model="form.code" maxlength="4" />
              </div>
              <img :src="codeImg" alt="code"  @click="resetCodeImg">
            </div>
            <button class="submit" @click="formSubmit">登 录</button>
        </div>
        <div class="decoretion">
            <img :src="require('@/assets/images/login_decoration.png')" alt="decoration" class="img" />
        </div>
    </div>
</template>
<script>
export default {
    name: "index",
    data() {
        return {
            logo: require("@/assets/logo/logo_web.png"),
            codeImg: "/api/verify.jpg",
            form: {
                loginName: "",
                password: "",
                code:'',
            },
        };
    },
    methods: {
        // 重置认证码
        resetCodeImg(){
            this.codeImg = '/api/verify.jpg?v=' + Date.parse(new Date());
        },
        // 表单提交
        formSubmit() {
            // const reg = /^1[3456789][0-9]{9}$/;
            const form = this.form;
            // if (form.loginName == "") {
            //     this.$toast({
            //         type: 'fail',
            //         icon: "",
            //         message: '请输入手机号'
            //     })
            //     return;
            // }
            // if (!reg.test(form.loginName)) {
            //     this.$toast({
            //         type: 'fail',
            //         icon: "",
            //         message: '手机号码格式错误'
            //     })
            //     return
            // }
            if (form.password == '') {
                this.$toast({
                    type: 'fail',
                    icon: "",
                    message: '请输入密码'
                })
                return
            }
            if (form.code.length != 4) {
                this.$toast({
                    type: 'fail',
                    icon: "",
                    message: '请输入4位认证码'
                })
                return
            }
            this.$toast.loading({ message: '加载中...', forbidClick: true, duration: 0 });
            this.$api.login(form).then(res => {
                this.$toast.clear();
                this.$toast({
                  type: 'success',
                  message: '登录成功',
                  onClose: () => {
                    this.$store.commit('updataToken',res.data.token);
                    if (res.data.user.level == 0) {
                        res.data.user.level = res.data.user.level+1
                    }
                    this.$store.commit('updateLevel',res.data.user.level);
                    this.$store.commit('updataUserData',res.data.user);
                    this.$router.replace('/index');
                  }
                })
            })
            .catch(() => {
                this.resetCodeImg()
            })
        },
    },
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/mobile/login.less";
</style>