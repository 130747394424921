import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/index';
import mobileRouters from "./mobile";
import pcRouters from "./pc";
import getAuth from './allRouter';
Vue.use(Router);

const windowWidth = window.innerWidth;
const bool = windowWidth > 1024 ? true : false;
let router = new Router({
    routes: bool ? [] : mobileRouters
});
router.onReady(() => {
    if(bool){
        if(store.state.rawAuth){
            let newRoute = getAuth(store.state.rawAuth) || [];
            if(newRoute.length > 0){
                const redirect = newRoute[0].redirect;
                newRoute.push({
                    name: '',
                    path: '/',
                    redirect
                })
            }
            let routers = pcRouters.concat(newRoute);
            router.addRoutes(routers);
        }else{
            router.addRoutes(pcRouters);
        }
    }
})
// 登录拦截
router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) { 
        // 判断跳转的路由是否需要登录
        if (store.state.token) { // vuex.state判断token是否存在
            if(store.state.windowWidth > 1024){
                // PC端权限判断
                if(store.state.noAuth == 1){
                    next({
                        path: '/403',
                        query: { 
                            redirect: to.fullPath 
                        } // 将跳转的路由path作为参数，登录成功后跳转到该路由
                    })
                }else{
                    next() // 已登录
                }
            }else{
                next() // 已登录
            }
        } else {
            next({
                path: '/login',
                query: { 
                    redirect: to.fullPath 
                } // 将跳转的路由path作为参数，登录成功后跳转到该路由
            })
        }
    } else {
        next();
    }
})
export default router