<template>
    <div>
        <div class="property">
            <div class="top_data">
                <div class="property_title" @click="choosePicker">
                    <p class="title" v-if="cellList.length>0">{{ cellList[cellListIndex].name }}</p>
                    <img :src="arrow" alt="arrow" class="arrow" />
                </div>
                <div class="account_title">
                    <p class="account_tips">数据统计</p>
                    <div @click="currentDetails">
                        <div class="router_arrow">
                            <p class="tips">详情</p>
                            <img :src="smallArrow" alt="arrow" class="arrow" />
                        </div>
                    </div>
                </div>
                <div class="account_data">
                    <div class="account_cell">
                        <p class="cell_tips">订单总数(单)</p>
                        <p class="cell_data">{{communityData.orderCount | intFilter}}</p>
                    </div>
                    <div class="account_cell">
                        <p class="cell_tips">订单总额(元)</p>
                        <p class="cell_data">{{communityData.orderTotalPrice | intFilter(2)}}</p>
                    </div>
                    <div class="account_cell">
                        <p class="cell_tips">客单价(元)</p>
                        <p class="cell_data">{{communityData.averagePrice | intFilter(2)}}</p>
                    </div>
                </div>
            </div>
            <div class="picker_box" v-show="showPicker">
                <div class="close_bg" @click="showPicker = false"></div>
                <van-picker title="选择" show-toolbar :default-index="cellListIndex" :columns="cellList" @confirm="onConfirm" @cancel="onCancel" value-key="name" />
            </div>
        </div>
        <div class="table_box">
            <div class="account_table_title">
                <p class="title">{{level | levelVal(1)}}列表</p>
                <p class="table_tips">数据统计{{uTime | cutOut(17,'sequence')}}</p>
            </div>
            <div class="table account_table">
                <div class="table_header">
                    <p class="title">{{level | levelVal(1)}}名称</p>
                    <p class="title">订单数</p>
                    <p class="title">订单金额</p>
                    <p class="title">客单价</p>
                </div>
                <div class="table_data_list">
                    <div class="table_cell" v-for="(item, index) in residentialList" :key="index">
                        <p class="cell property_name" @click="jumpDetails" :data-level="item.level" :data-id="item.id" :data-name="item.name || item.leaderName" :data-leader="item.leaderId">{{ item.name || item.leaderName}}</p>
                        <p class="cell">{{item.orderCount | intFilter}}</p>
                        <p class="cell">{{item.totalPrice | intFilter(2)}}</p>
                        <p class="cell">{{item.totalPrice/item.orderCount | intFilter(2)}}</p>
                    </div>
                </div>
            </div>
        </div>
        <van-empty v-if="!residentialList || residentialList.length<1" description="暂无信息~" />
    </div>
</template>
<script>
export default {
    name: "property",
    data() {
        return {
            arrow: require("@/assets/icons/arrow_down.png"),
            smallArrow: require("@/assets/icons/arrow_right.png"),
            cellList: [],
            residentialList: [],
            showPicker: false,
            cellListIndex: 0,
            communityData: {},
            uTime: '',
            level: 8
        };
    },
    watch: {
        $route() {
            this.getUpLevelBindInfo(this.$route.query.id);
        },
    },
    mounted() {
        this.getUpLevelBindInfo(this.$route.query.id);
    },
    methods: {
        // 获取物业列表
        getUpLevelBindInfo(id) {
            let getCompanyList = this.$common.getCompanyList();
            let getPropertyList = this.$common.getPropertyList();
            let company = getCompanyList.findIndex((value) => { return value.id == id });
            let property = getPropertyList.findIndex((value) => { return value.id == id });
            if (company >= 0) {
                this.cellList = getCompanyList
                this.cellListIndex = company
            }else{
                this.cellList = getPropertyList
                this.cellListIndex = property
            }
            this.changeIndex(this.cellListIndex);
        },
        changeIndex(i){
            if(i == -1){
                this.$router.replace('/index');
                return
            }
            let obj = this.cellList[i];
            this.level = obj.level;
            this.$store.commit('updateTitle', obj.name);
            this.getLevelStatistics(obj.id, this.level + 1);
        },
        // 跳转详情
        jumpDetails(e) {
            let bindId = e.currentTarget.dataset.id;
            let leader = e.currentTarget.dataset.leader;
            let level = parseInt(e.currentTarget.dataset.level) + 1;
            if (leader) {
                this.$store.commit('updateTitle', e.currentTarget.dataset.name);
                this.$router.push({
                    path: 'residentialDetails',
                    query: {
                        id: leader,
                        leaderId:leader
                    }
                })
            } else {
                this.$store.commit('updataPropertyList', this.residentialList);
                this.$router.push({ query: { id: bindId } })
            }
        },
        // 跳转当前详情
        currentDetails(){
            let bindId = this.cellList[this.cellListIndex].id;
            this.$router.push({
                path: 'residentialDetails',
                query: {
                    id: bindId
                }
            })
        },
        // 获取下级列表
        getLevelStatistics(bindId, level) {
            let token = this.$common.getToken();
            this.$toast.loading({ message: '加载中...', forbidClick: true, duration: 0 });
            let isLast= level == this.$store.state.highestLevel;
            if (isLast) {
                //最后一级，小区
                this.$api.getLeaderStatistics({ token, bindId }).then(callback);
            } else {
                this.$api.getLevelStatistics({ token, bindId, level }).then(callback);
            }

            let This = this;
            function callback(res) {
                This.$toast.clear();
                This.communityData = {
                    orderCount: res.data.orderCount,
                    orderTotalPrice: res.data.orderTotalPrice,
                    averagePrice: res.data.orderTotalPrice / res.data.orderCount
                }
                This.uTime = res.data.updateTime;
                This.residentialList = isLast? res.data.leaderStatistics: res.data.bindInfo;
            }
        },
        choosePicker() {
            this.showPicker = true;
        },
        onConfirm(value, index) {
            this.cellListIndex = index;
            this.changeIndex(index);
            this.showPicker = false;
        },
        onCancel() {
            this.showPicker = false;
        },
    },
};
</script>
<style lang="less" scoped>
@import url("../../../assets/less/mobile/property.less");
@import url("../../../assets/less/mobile/common.less");
</style>