import Vue from 'vue';
import Vuex from 'vuex'
//使用
Vue.use(Vuex)

const initData = {
    token: localStorage.getItem('token') || '',
    windowWidth: localStorage.getItem('windowWidth') || '',
    current: localStorage.getItem('updateMenu') || 0,
    userData: localStorage.getItem('userData') || '',
    rawAuth: localStorage.getItem('rawAuth') || '',
    noAuth: localStorage.getItem('noAuth') || 0,
    companyList:[],//公司
    propertyList:[],//物业
    title:localStorage.getItem('title') || '',//标题
    level:'',//权限
    highestLevel:5,//最高权限
    filter: '',
}

const store = new Vuex.Store({
    state: initData,
    mutations: {
        // state 的操作
        refreshWindow(state, num) {
            state.windowWidth = num;
            localStorage.setItem('windowWidth',num);
        },
        // update Token
        updataToken(state, params) {
            state.token = params;
            localStorage.setItem('token',params);
        },
        // 切换menu
        updateMenu(state, params) {
            state.current = params;
            localStorage.setItem('current',params);
        },
        // 更新权限信息
        updateAuth(state,params){
            state.rawAuth = params;
            localStorage.setItem('rawAuth', params);
        },
        // 更新是否无权限
        updateNoauth(state,params){
            state.noAuth = params;
            localStorage.setItem('noAuth', params);
        },
         // state 的操作
         updateTitle(state, params) {
            state.title = params;
            localStorage.setItem('title', params);
        },
        // level 的操作
        updateLevel(state, params) {
            state.level = params;
            localStorage.setItem('level', params);
        },
        // update UserData登录数据
        updataUserData(state, params) {
            state.userData = params;
            if(state.windowWidth < 1024){
                params = JSON.stringify(params)
            }
            localStorage.setItem('userData', params);
        },
        updataCompanyList(state, params) {
            state.companyList = params;
            localStorage.setItem('companyList', JSON.stringify(params));
        },
        updataPropertyList(state, params) {
            state.propertyList = params;
            localStorage.setItem('propertyList', JSON.stringify(params));
        },
        // 筛选的数据
        updateFilter(state,params){
            state.filter = params;
            localStorage.setItem('filter', JSON.stringify(params));
        },
        // 用原数据进行还原
        logout(state) {
          Object.assign(state, initData);
          localStorage.clear();
        }
    }
})

export default store;