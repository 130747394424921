<template>
    <div class="index">
        <form class="formBox">
            <div class="form_cell">
                <label>当前密码</label>
                <div class="form_item">
                    <input type="password" placeholder="请输入当前密码" maxlength="18" v-model="form.password" autocomplete>
                </div>
            </div>
            <div class="form_cell">
                <label>新密码</label>
                <div class="form_item">
                    <input type="password" placeholder="请输入新密码" maxlength="18" v-model="form.newPassword" autocomplete>
                </div>
            </div>
            <div class="form_cell">
                <label>确认密码</label>
                <div class="form_item">
                    <input type="password" placeholder="请再次输入新密码" maxlength="18" v-model="form.confirmPassword" autocomplete>
                </div>
            </div>
            <!-- <div class="form_cell">
                <label>验证码</label>
                <div class="form_item">
                    <input type="text" placeholder="请输入验证码" maxlength="4" v-model="form.code">
                    <span class="badge-info">获取验证码</span>
                </div>
            </div> -->
            <div class="btnBox">
                <button class="smallBtn" @click="formSubmit">确认修改</button>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    name: 'modifyPassword',
    data() {
        return {
            form: {
                password: '',
                newPassword:'',
                confirmPassword:'',
                code:'',
                isSendCode:false,//是否发送验证码
            }
        }
    },
    mounted() {
        this.$store.commit('updateTitle', '修改密码');
    },
    methods: {
        formSubmit() {
            var pPattern = /^.*(?=.{8,16})((?=.*\d)(?=.*[A-Z]))|((?=.*\d)(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*? ]))|((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[!@#$%^&*? ]))|((?=.*[a-z])(?=.*[!@#$%^&*? ])).*$/;
            if (this.form.password.length<5) {
                this.$toast({
                  type: 'fail',
                  icon: "",
                  message: '当前密码不能少于6位'
                })
                return;
            }
            if (!pPattern.test(this.form.newPassword)) {
                this.$toast({
                  type: 'fail',
                  icon: "",
                  message: '请输入8-16位，字母、数字、特殊字符中两个以上组成'
                })
                return;
            }
            if (this.form.newPassword != this.form.confirmPassword) {
                this.$toast({
                  type: 'fail',
                  icon: "",
                  message: '确认密码不正确'
                })
                return;
            }
            let validation = this.$common.getToken();
            this.$toast.loading({ message: '加载中...', forbidClick: true, duration: 0 });
            this.$api.updatePassword({validation,oldPassword:this.form.password,newPassword:this.form.confirmPassword}).then(() => {
                return this.$api.userLogout({validation});
            }).then(() => {
                this.$toast.clear();
                this.$toast({
                  type: 'success',
                  message: '修改成功',
                  onClose: () => {
                    this.$store.commit('logout');
                    this.$router.replace('/login');
                  }
                })
            })
        }
    },
}
</script>
<style scoped lang="less">
@import url("../../../assets/less/mobile/modifyPassword.less");
@import url("../../../assets/less/mobile/common.less");
</style>