<template>
  <div class="noFound">
    <img :src="icon" alt="403" class="icon" />
    <p class="tips">您还没有权限查看哦，快联系管理员添加吧～</p>
    <!-- <button class="btn" @click="loginOut">退出登录</button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      icon: require("@/assets/error/none_auth.png"),
    };
  },
  methods: {
    loginOut() {
      const loading = this.$loading({
        lock: true,
        text: "正在退出登录",
        spinner: "el-icon-loading",
        background: "rgba(255,255,255, 0.2)",
      });
      this.$api
        .userLogout({
          validation: this.$store.state.token,
        })
        .then(
          () => {
            loading.close();
            this.$message.success("退出成功");
            this.$store.commit("updataToken", "");
            this.$store.commit("updateNoauth", 0);
            this.$router.replace("/login");
          },
          (error) => {
            loading.close();
            this.$message.error(error.msg);
          }
        );
    },
  },
};
</script>

<style scoped lang="less">
.noFound {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  .icon {
    width: 692px;
    height: 642px;
    -webkit-filter: grayscale(40%);
    object-fit: contain;
  }
  .tips {
    font-size: 24px;
    color: #333;
    filter: grayscale(40%);
  }
  .btn {
    background: #ff7930;
    margin-top: 30px;
    border-radius: 30px;
    font-size: 24px;
    color: #fff;
    padding: 10px 30px;
    filter: grayscale(100%);
    cursor: pointer;
  }
  .btn:hover {
    filter: grayscale(0);
  }
  .btn:active {
    filter: grayscale(100%);
  }
}
</style>