<template>
  <div class="index">
    <div class="login_bg"></div>
    <transition name="el-fade-in">
      <div v-show="show" class="login_box">
          <div class="logo_title">
            <img :src="logo" alt="logo" class="logo" />
            <p class="title">餸鲜生数据助手</p>
          </div>
          <div class="form_content">
            <div class="form_cell">
              <input
                type="text"
                placeholder="请输入账号"
                v-model="form.account"
                @keyup.enter="formSubmit"
              />
            </div>
            <div class="form_cell">
              <input
                type="password"
                placeholder="请输入密码"
                v-model="form.password"
                @keyup.enter="formSubmit"
              />
            </div>
            <div class="form_cell">
              <input
                type="text"
                placeholder="请输入验证码"
                v-model="form.code"
                @keyup.enter="formSubmit"
              />
              <img :src="codePic" alt="code" class="code" @click="refresh" />
            </div>
            <button
              class="submit"
              :disabled="!form.account || !form.password || !form.code"
              @click="formSubmit"
              v-if="!loading"
            >
              登录
            </button>
            <button class="submit" v-else disabled>
              <i class="el-icon-loading icon"></i> 登录中
            </button>
          </div>
        </div>
    </transition>
  </div>
</template>

<script>
import getAuth from '../../../routers/allRouter';
export default {
  name: "index",
  data() {
    return {
      logo: require("@/assets/logo/logo_web.png"),
      form: {
        account: "",
        password: "",
        code: "",
      },
      codePic: "/api/verify.jpg",
      loading: false,
      show: false
    };
  },
  beforeCreate(){
    this.$createRouter();
  },
  created() {
    this.$store.commit("updateNoauth", 0);
  },
  mounted(){
    localStorage.clear();
    this.$store.commit("updataToken", "");
    this.$store.commit("updateNoauth", 0);
    this.refresh();
    this.show = true;
  },
  methods: {
    formSubmit() {
      if(this.loading){
        return
      }
      this.submitCheck();
    },
    submitCheck() {
      const form = this.form;
      if (form.account == "") {
        this.$message.error("请输入账号");
        return;
      }
      if (form.password == "") {
        this.$message.error("请输入密码");
        return;
      }
      this.loading = true;
      this.$api
        .login({
          loginName: form.account,
          password: form.password,
          code: form.code,
        })
        .then(
          (res) => {
            let data = res.data;
            let token = data.token;
            this.$store.commit("updataToken", token);
            let userData = data.user;
            const user = {
              loginName: userData.loginName,
              id: userData.id,
              status: userData.status,
              role: userData.role,
              power: userData.power,
              bindId: userData.bindId,
              createTime: userData.createTime,
              companyName: userData.companyName,
              level: userData.level,
              topId: userData.topId,
              contactPerson: userData.contactPerson,
            };
            this.$store.commit("updataUserData", JSON.stringify(user));
            const rolesDetails = userData.rolesDetails;
            if (!rolesDetails && userData.level > 1) {
              this.$router.replace("/403");
              this.$store.commit("updateNoauth", 1);
            } else {
              // 更新权限列表
              this.$store.commit("updateNoauth", 0);
              // this.$store.commit("updateAuth", 'root');
              // this.$router.addRoutes(getAuth('root'));
              // this.$router.replace("/index");
              if(userData.level < 2){
                // 最高权限账号
                this.$store.commit("updateAuth", 'root');
                this.$router.addRoutes(getAuth('root'));
                this.$router.replace("/index");
              }else{
                this.$store.commit("updateAuth", rolesDetails);
                const newRoutes = getAuth(rolesDetails);
                this.$router.addRoutes(newRoutes);
                if(newRoutes.length < 1){
                  this.$router.replace("/403");
                }else{
                  this.$router.replace(newRoutes[0].children[0].path);
                }
              }
            }
            this.$message({
              message: '登录成功',
              type: 'success',
              duration: 1000
            });
          },
          (error) => {
            this.refresh();
            this.$message.error(error.msg || "系统错误");
            this.loading = false;
          }
        );
    },
    // 刷新验证码
    refresh() {
      const radom = Math.random();
      this.codePic = `/api/verify.jpg?${radom}`;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../../assets/less/pc/login.less");
</style>