<template>
    <div class="index">
        <div class="table_box">
            <div class="table_list">
                <p :class="['table_cell', item.id == dayType ? 'active' : '']" v-for="(item, index) in dayList" :key="index" :data-id="item.id" @click="chooseType">
                    {{ item.title }}
                </p>
            </div>
            <div class="table">
                <div class="table_header">
                    <p class="title">日期</p>
                    <p class="title">订单数</p>
                    <p class="title" v-if="dayType != 'DAY'">日均订单</p>
                    <p class="title">订单金额</p>
                    <p class="title">客单价</p>
                </div>
                <div class="table_data_list">
                    <div class="table_cell" v-for="(item, index) in order" :key="index">
                        <p class="cell">{{item.day | cutOut(5)}}</p>
                        <p class="cell">{{item.ordersCount | intFilter}}</p>
                        <p class="cell" v-if="dayType != 'DAY'">{{ item.averageOrder | intFilter(2)}}</p>
                        <p class="cell">{{ item.ordersAmount | intFilter(2)}}</p>
                        <p class="cell">{{ item.averageAmount | intFilter(2)}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'residentialDetails',
    data() {
        return {
            dayType: 'DAY',
            dayList: [{
                    title: "日",
                    id: 'DAY',
                },
                {
                    title: "周",
                    id: 'WEEK',
                },
                {
                    title: "月",
                    id: 'MONTH',
                },
            ],
            order: [],
        }
    },
    mounted() {
        this.statisticsData('DAY')
    },
    methods: {
        // 选择日周月
        chooseType(e) {
            const id = e.currentTarget.dataset.id;
            this.dayType = id;
            this.statisticsData(id);
        },
        // 获取日周月数据
        statisticsData(type){
            let bindId = this.$route.query.id;
            let token = this.$common.getToken();
            if (this.$route.query.leaderId) {//最后一级，小区
                this.$api.getLeaderStatisticsByType({token,type,leaderId:bindId}).then(callback)
            }else{
                if (type == 'DAY') {
                    this.$api.statisticsByDay({ token,bindId}).then(callback)
                }else if (type == 'WEEK') {
                    this.$api.statisticsByWeek({ token,bindId}).then(callback)
                }else if (type == 'MONTH') {
                    this.$api.statisticsByMonth({ token,bindId}).then(callback)
                }
            }
            let This = this;
            function callback(res){
                This.$toast.clear();
                let list = res.data;
                let arr = []
                // 如果是数组
                if(list instanceof Array){
                    list.map((e,i)=>{
                        e?e:e = {};
                        e.ordersAmount == e.ordersAmount?e.ordersAmount:0;
                        e.ordersCount == e.ordersCount?e.ordersCount:0;
                        e.averageAmount = e.ordersCount == 0?0:e.ordersAmount/e.ordersCount;//客单价
                        e.averageOrder = type == 'WEEK'? e.ordersCount/7:e.ordersCount/30;//日均订单
                        arr.push(e);
                    })
                }else{
                    for(let [k,v] of Object.entries(list)){
                        let ordersAmount = v && v.ordersAmount?v.ordersAmount:0;
                        let ordersCount = v && v.ordersCount?v.ordersCount:0;
                        arr.push({
                            day:k,
                            ordersAmount:ordersAmount,
                            ordersCount:ordersCount,
                            averageAmount:ordersCount == 0?0:ordersAmount/ordersCount,//客单价
                            averageOrder:type == 'WEEK'?ordersCount/7:ordersCount/30,//日均订单
                        })
                    }
                }
                This.order = arr
            }
        }
    },
}
</script>
<style scoped lang="less">
@import url("../../../assets/less/mobile/residentialDetails.less");
@import url("../../../assets/less/mobile/common.less");
</style>