<template>
  <div class="menu">
      <div :class="['menu_cell', current == index ? 'active' : '']" v-for="(item,index) in list" :key="index" :data-index="index" @click="changePage">
          <img :src="current == index ? item.selectIcon : item.icon" alt="Icon" class="icon" />
          <p class="title">{{ item.title }}</p>
      </div>
  </div>
</template>

<script>
export default {
    name: 'SMenu',
    data(){
        return {
            list: [{
                icon: require('@/assets/menu/index.png'),
                selectIcon: require('@/assets/menu/index_select.png'),
                title: '基础数据',
                link: '/index'
            },{
                icon: require('@/assets/menu/mine.png'),
                selectIcon: require('@/assets/menu/mine_select.png'),
                title: '我的账号',
                link: '/mine'
            }]
        }
    },
    props: {
        current: {
            type: String,
            default: '0',
        }
    },
    watch: {
        $route: {
            handler: function(val){
                if (val) {
                    this.$store.commit('updateMenu',val.meta.tab)
                    this.$emit('update:current',String(val.meta.tab));
                }
            },
            deep: true
        }
    },
    mounted() {
        this.$emit('update:current',String(localStorage.getItem('current') || '0'));
    },
    methods: {
        changePage(e){
            let index = e.currentTarget.dataset.index;
            const link = this.list[index].link;
            let route = this.$route.path;
            if(route == link){
                return
            }
            this.$store.commit('updateMenu',index);
            this.$router.replace(link);
        }
    }
}
</script>

<style scoped lang="less">
.menu{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: #fff;
    height: 98px;
    box-shadow: 0px -2px 8px 0px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    .menu_cell{
        .icon{
            width: 48px;
            height: 48px;
            margin: 0 auto;
        }
        .title{
            font-size: 24px;
            color: #999;
            padding-top: 4px;
        }
    }
    .active{
        .title{
            color: #FF7930;
        }
    }
}
</style>