<template>
  <div class="content">
    <div class="filter_box">
      <div class="filter_left">
        <!-- <input type="text" v-model="name" placeholder="名称" /> -->
        <div class="select_box">
          <el-select
            v-model="company"
            placeholder="请选择"
            filterable
            @change="chooseFirst"
          >
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select_box">
          <el-select
            v-model="property"
            placeholder="请选择"
            clearable
            filterable
            :disabled="company == ''"
            @change="chooseSecond"
            @clear="clear(2)"
          >
            <el-option
              v-for="item in propertyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select_box">
          <el-select
            v-model="third"
            placeholder="请选择"
            clearable
            filterable
            :disabled="property == ''"
            @change="chooseThird"
            @clear="clear(3)"
          >
            <el-option
              v-for="item in thirdList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select_box">
          <el-date-picker
            v-model="range"
            :type="pickerType"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="picker"
            v-show="day == 1"
          >
          </el-date-picker>
          <span v-show="day == 7">
            <el-date-picker
              v-model="startWeek"
              :type="pickerType"
              format="yyyy 第 WW 周"
              :picker-options="picker"
              value-format="yyyy-MM-dd"
              placeholder="开始周"
              @change="pickStart"
            >
            </el-date-picker>
            <span class="blank"></span>
            <el-date-picker
              :disabled="!startWeek"
              v-model="endWeek"
              :type="pickerType"
              format="yyyy 第 WW 周"
              :picker-options="endPicker"
              value-format="yyyy-MM-dd"
              placeholder="结束周"
            >
            </el-date-picker>
          </span>
          <el-date-picker
            v-model="range"
            :type="pickerType"
            :picker-options="picker"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            value-format="yyyy-MM-dd"
            v-show="day == 30"
          >
          </el-date-picker>
        </div>
        <button class="submit" @click="filter">筛选</button>
      </div>
      <!-- <button class="export">导出</button> -->
    </div>
    <div class="day_filter">
      <div
        :class="['day', item.value == day ? 'active' : '']"
        v-for="(item, index) in dayList"
        :key="index"
        :data-index="index"
        @click="chooseDay"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="trend_table">
      <div class="table_box">
        <el-table :data="tableData" :stripe="true" ref="table">
          <el-table-column prop="id" label="id" width="80"></el-table-column>
          <el-table-column prop="name" label="名称" width="180"></el-table-column>
          <el-table-column prop="startDate" :label="day != 1 ? '开始日期' : '日期'"></el-table-column>
          <el-table-column prop="endDate" label="结束日期" v-if="day != 1"></el-table-column>
          <el-table-column
            prop="orderCount"
            label="订单交易数"
          ></el-table-column>
          <el-table-column prop="totalPrice" label="订单交易额"></el-table-column>
          <el-table-column prop="customerPrice" label="客单价"></el-table-column>
          <el-table-column prop="averageDayTransactionsCount" label="日均交易数"></el-table-column>
          <el-table-column prop="averageDayTransactionsPrice" label="日均交易额"></el-table-column>
        </el-table>
      </div>
      <div class="loading" v-if="loading">
        <i class="el-icon-loading"></i>
      </div>
    </div>
    <div class="page_box">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="total, prev, pager, next, jumper"
        background
        :page-size="rows"
        :total="totalList"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderData",
  data() {
    return {
      name: "",
      company: "",
      companyId: '',
      companyList: [],
      range: "",
      startWeek: '',
      endWeek: '',
      picker: {
        disabledDate: (value) => {
          const time = new Date(value).getTime();
          if (time > new Date().getTime() || time < new Date('2020-01-01 00:00:00').getTime()) {
            return true;
          }
          return false;
        },
        firstDayOfWeek: 1
      },
      endPicker: {
        disabledDate: (value) => {
          if (new Date(value).getTime() <= this.resetEndWeek() || new Date(value).getTime() > new Date().getTime()) {
            return true;
          }
          return false;
        },
        firstDayOfWeek: 1
      },
      currentPage: 1,
      rows:10,
      totalList: 0,
      day: 1,
      dayList: [
        {
          title: "日数据",
          value: 1,
          type: 'DAY'
        },
        {
          title: "周数据",
          value: 7,
          type: 'WEEK'
        },
        {
          title: "月数据",
          value: 30,
          type: 'MONTH'
        },
      ],
      tableData: [],
      property: "",
      propertyList: [],
      third: "",
      thirdList: [],
      pickerType: "daterange",
      loading: true,
      orderStatus: ''
    };
  },
  created() {
    this.companyId = this.$route.query.id;
    this.orderStatus = this.$route.query.orderStatus;
    this.getPre();
  },
  beforeUpdate(){
    this.$nextTick(() => { //在数据加载完，重新渲染表格
        this.$refs['table'].doLayout();
    })
  },
  methods: {
    // 选择查看数据的时间
    chooseDay(e) {
      const index = e.currentTarget.dataset.index;
      const day = this.dayList[index].value;
      this.day = day;
      this.range = "";
      if (day == 1) {
        this.pickerType = "daterange";
      } else if (day == 7) {
        this.pickerType = "week";
      } else if (day == 30) {
        this.pickerType = "monthrange";
      }
      
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.currentPage = e;
      this.getList();
    },
    // 选择一级公司
    chooseFirst(e) {
      const result = this.companyList.find((item) => {
        return item.id == e;
      });
      if (!result.childList) {
        this.propertyList = [];
      } else {
        this.propertyList = result.childList;
      }
      this.property = "";
      this.third = "";
      this.companyId = e;
    },
    // 选择二级公司
    chooseSecond(e) {
      if (!e) {
        this.thirdList = [];
        this.third = "";
        return;
      }
      const result = this.propertyList.find((item) => {
        return item.id == e;
      });
      if (!result.childList) {
        this.thirdList = [];
      } else {
        this.thirdList = result.childList;
      }
      this.third = "";
      this.companyId = e;
    },
    // 选择三级公司
    chooseThird(e){
      this.companyId = e;
    },
    // 清空选项
    clear(e){
      if(e == 3){
        this.companyId = this.property;
      }else if(e == 2){
        this.companyId = this.company;
      }
    },
    // 筛选
    filter() {
      const day = this.day;
      if(day == 7){
        if(this.startWeek && this.endWeek == ''){
          this.$message.error("请选择结束周");
          return
        }
      }
      this.currentPage = 1;
      this.getList();
    },
    getPre() {
      this.$api.admin
        .getCompanyDropDownList({
          level: 2,
        })
        .then(
          (res) => {
            this.companyList = res.data;
            this.getList();
          },
          (error) => {
            this.$message.error(error.msg);
          }
        );
    },
    // 选择开始周
    pickStart(){
      this.endWeek = '';
      this.resetEndWeek();
    },
    resetEndWeek(){
      let startWeek = this.startWeek;
      let disStart = new Date(new Date(startWeek + ' 00:00:00').getTime() + 5 * 24 * 60 * 60 * 1000);
      return disStart
    },
    getList(){
      const day = this.dayList.find(item => {
        return item.value == this.day;
      });
      const type = day.type;
      const property = this.property;
      const third = this.third;
      const companyId = this.companyId;
      const page = this.currentPage;
      const range = this.range || [];
      const orderStatus = this.orderStatus;
      let startDate = null;
      let endDate = null;
      let level = null;
      if(!third && !property){
        level = 2;
      }else if(!third && property){
        level = 3;
      }else if(third){
        level = 4;
      }
      if(type == 'DAY' || type == 'MONTH'){
        startDate = range[0] || '';
        endDate = range[1] || '';
        if(type == 'MONTH' && range[1]){
          let day = endDate.split('-');
          day[2] = new Date().getDate();
          day[2] = day[2] < 10 ? '0' + day[2] : day[2] 
          endDate = day[0] + '-' + day[1] + '-' + day[2];
        }
      }else if(type == 'WEEK'){
        startDate = this.startWeek ? this.resetTime(this.startWeek,0) : '';
        endDate = this.endWeek ? this.resetTime(this.endWeek, 6) : '';
      }
      let data = {
        type,
        level,
        companyId,
        page
      }
      if(startDate){
        data.startDate = startDate
      }
      if(endDate){
        data.endDate = endDate;
        data.rows = this.getRows(startDate,endDate,type);
        data.rows = 10;
      }else{
        data.rows = 10;
      }
      if(orderStatus == 1){
        data.orderStatus = 10;
      }else{
        data.orderStatus = 40;
      }
      this.loading = true;
      this.$api.admin.getTransactionStatisticsByTimePeriod(data).then(res => {
        this.loading = false;
        this.$nextTick(() => {
          if(page == 1){
            this.totalList = res.data.total;
          }
          this.tableData = res.data.rows;
          this.$refs["table"].doLayout();
        })
      },error => {
        this.loading = false;
        this.$message.error(error.msg)
      })
    },
    // 重置周选择的起始时间
    resetTime(params, endParams){
      const oneDay = 24 * 60 * 60 * 1000;
      let date = new Date((new Date(params + ' 00:00:00').getTime() - oneDay * (1 -  endParams)));
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if(month < 10){
        month = '0' + month;
      }
      if(day < 10){
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
    },
    // 获取每页条数
    getRows(start,end,type){
      start = new Date(start + ' 00:00:00').getTime();
      end = new Date(end + ' 00:00:00').getTime();
      let release = end - start;
      let rows = 0;
      if(type == 'DAY'){
        rows = parseInt(release / 24 / 60 / 60 / 1000);
      }else if(type == 'WEEK'){
        rows = parseInt(release / 24 / 60 / 60 / 1000 / 7);
      }else if(type == 'MONTH'){
        rows = parseInt(release / 24 / 60 / 60 / 1000 / 30);
      }
      rows = rows > 10 ? 10 : rows + 1;
      return rows;
    }
  },
};
</script>

<style scoped lang="less">
@import url("../../../../assets/less/pc/order/index.less");
.content .trend_table {
  margin-top: 0;
  min-height: 60vh;
  position: relative;
  /deep/ .el-table{
    width: 99.9% !important;
  }
}
/deep/ .el-range-editor {
  margin-top: 0;
}
.content .filter_box .filter_left .submit {
  margin-left: 0;
}
</style>