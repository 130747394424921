// 登录
import login from '../views/PC/login/index';

// 404 页面
import four from '../views/error/404';
// 403 页面
import three from '../views/error/403';

import store from '../store/index';

let pcRouters = [{
    name: '',
    path: '/login',
    component: login
},{
    path: '/404',
    component: four
},{
    path: "/403",
    component: three,
    meta: {
        requireAuth: true,
        views: true
    }
},{
    path: "*",
    redirect: "/404"
}];
if(!store.state.rawAuth){
    pcRouters.unshift({
        name: '',
        path: '/',
        redirect: '/login',
    });
}
export default pcRouters