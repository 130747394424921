<template>
  <div class="index">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {

        
    };
  }
};
</script>

<style scoped lang="less">
.index{
  padding: 88px 0 0
}
</style>