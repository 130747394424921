<template>
  <div class="content">
    <div class="filter_box">
      <div class="filter_left">
        <input type="text" v-model="name" placeholder="名称" />
        <el-date-picker
          v-model="range"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="picker"
        >
        </el-date-picker>
        <button class="submit">筛选</button>
      </div>
      <!-- <button class="export">导出</button> -->
    </div>
    <div class="trend_table">
      <div class="table_box">
        <el-table :data="tableData" style="width: 100%" :stripe="true">
          <el-table-column prop="id" label="id" width="80"></el-table-column>
          <el-table-column prop="tool" label="渠道" width="180"> </el-table-column>
          <el-table-column prop="access" label="访问量"> </el-table-column>
          <el-table-column prop="register" label="注册量"> </el-table-column>
          <el-table-column prop="newUser" label="新增用户"> </el-table-column>
          <el-table-column prop="create" label="创建时间"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div class="table_option">
                <el-popover placement="right" width="400" trigger="click"  v-if="scope.row.qrcode">
                  <el-image :src="scope.row.qrcode"></el-image>
                  <button class="btn blue" slot="reference">推广二维码</button>
                </el-popover>
                <button
                  class="btn red"
                  :data-index="scope.$index"
                  @click="delItem"
                >
                  删除
                </button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page_box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="total, prev, pager, next, jumper"
        background
        :total="2"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "trend",
  data() {
    return {
      name: "",
      range: "",
      currentPage: 1,
      picker: {
        disabledDate: (value) => {
          if (new Date(value).getTime() > new Date().getTime()) {
            return true;
          }
          return false;
        },
      },
      tableData: [
        {
          id: 1,
          tool: "广州市海格洛科技有限公司",
          access: "12,900",
          register: "29,098.00",
          newUser: "20",
          create: "2020-10-27 15:45:32",
          qrcode:
            "https://farm3.staticflickr.com/2811/33823326832_059359647d_k.jpg?momo_cache_bg_uuid=66fd2487-49d4-4830-97e1-1252c13abe31",
        },
        {
          id: 2,
          tool: "广州市海格洛科技有限公司",
          access: "12,900",
          register: "29,098.00",
          newUser: "20",
          create: "2020-10-27 15:45:32",
          qrcode:
            "https://farm3.staticflickr.com/2811/33823326832_059359647d_k.jpg?momo_cache_bg_uuid=66fd2487-49d4-4830-97e1-1252c13abe31",
        },
      ],
    };
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(e) {
      this.currentPage = e;
    },
    // 删除记录
    delItem(e) {
      const index = e.currentTarget.dataset.index;
      this.$confirm("是否确定删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.tableData.splice(index, 1);
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../../../assets/less/pc/analyze/trend.less");
</style>