<template>
  <div class="content">
    <div class="filter_box">
      <div class="filter_left">
        <div class="filter_cell">
          <input type="text" placeholder="名称" v-model="name" />
        </div>
        <div class="filter_cell">
          <el-date-picker
            v-model="range"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="picker"
          >
          </el-date-picker>
        </div>
        <div class="filter_cell">
          <button class="submit">筛选</button>
        </div>
      </div>
      <!-- <div class="filter_right">
        <button class="export">导出</button>
      </div> -->
    </div>
    <div class="table_box">
      <div class="table_content">
        <el-table :data="tableData" style="width: 100%" :stripe="true">
          <el-table-column prop="id" label="id" width="80"></el-table-column>
          <el-table-column label="用户"  width="180">
            <template slot-scope="scope">
              <div class="user_data">
                <el-avatar :src="scope.row.avatar">{{
                  scope.row.nickname
                }}</el-avatar>
                <p class="nickname">{{ scope.row.nickname }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="shareTime" label="分享次数"> </el-table-column>
          <el-table-column prop="newAdd" label="分享新增"> </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page_box">
       <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="total, prev, pager, next, jumper"
        background
        :total="2"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "user",
  data() {
    return {
      name: "",
      range: "",
      picker: {
        disabledDate: (value) => {
          if (new Date(value).getTime() > new Date().getTime()) {
            return true;
          }
          return false;
        },
      },
      currentPage: 1,
      tableData: [
        {
          id: 1,
          avatar:
            "https://tse3-mm.cn.bing.net/th/id/OIP.yYr3gtNzcXZCslDkK_GnnQAAAA?pid=Api&rs=1",
          nickname: "微信昵称",
          shareTime: "12,900",
          newAdd: "0",
        },
        {
          id: 2,
          avatar:
            "https://img.gmz88.com/uploadimg/image/20190307/15519273565c80883c7a05a7.18473586.jpeg",
          nickname: "微信昵称",
          shareTime: "12,900",
          newAdd: "0",
        },
      ],
    };
  },
  methods: {
    handleSizeChange(){},
    handleCurrentChange(e){
      this.currentPage = e
    }
  }
};
</script>

<style scoped lang="less">
@import url("../../../../assets/less/pc/share/user.less");
</style>