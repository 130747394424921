import Vue from 'vue'
import App from './App.vue'
import router from './routers/router'
import './plugins/axios'
import ElementUI from 'element-ui'
import store from './store/index'
import api from './api/index'
import init from './routers/init';
import 'lib-flexible/flexible'
import { Toast,Picker,Empty } from 'vant'
import 'vant/lib/toast/style'
import 'vant/lib/picker/style'
import 'vant/lib/empty/style'
import filters from './filter/index'
import common from './commonFunction/index'
import utils from './assets/utils/utils';

Object.keys(filters).forEach(key=>{
    Vue.filter(key,filters[key])//插入过滤器名和对应方法
})

Vue.use(router)
Vue.use(ElementUI)
Vue.use(store)
Vue.use(Toast);
Vue.use(Picker)
Vue.use(Empty)
Vue.prototype.$api = api
Vue.prototype.$common = common
Vue.prototype.$utils = utils
Vue.prototype.$createRouter = init;
Vue.config.productionTip = false
// 开启chrome调试
Vue.config.devtools = process.env.NODE_ENV === 'production' ? false : true;

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
