<template>
  <div class="index">
    <ul class="tab_bar">
      <li
        :class="['tab_cell', item.id == current ? 'active' : '']"
        v-for="(item, index) in tabbar"
        :key="index"
        :data-id="item.id"
        @click="chooseTab"
      >
        {{ item.title }}
      </li>
    </ul>
    <div class="data_box" v-show="current == 0">
      <div class="today_data">
        <div class="top_data">
          <p class="top_title">今日概况</p>
          <p class="top_date">数据更新至{{ updateTime }}</p>
        </div>
        <div class="data_list">
          <div class="data_cell">
            <p class="cell_tips">订单总数(单)</p>
            <p class="data">{{ today.orderCount | intFilter }}</p>
            <p class="bottom_tips">
              昨日：{{ yesterday.orderCount | intFilter }}
            </p>
          </div>
          <div class="data_cell">
            <p class="cell_tips">订单总额(元)</p>
            <p class="data">{{ today.orderPrice | intFilter(2) }}</p>
            <p class="bottom_tips">
              昨日：{{ yesterday.orderPrice | intFilter(2) }}
            </p>
          </div>
          <div class="data_cell">
            <p class="cell_tips">客单价(元)</p>
            <p class="data">{{ today.averagePrice | intFilter(2) }}</p>
            <p class="bottom_tips">
              昨日：{{ yesterday.averagePrice | intFilter(2) }}
            </p>
          </div>
        </div>
      </div>
      <div class="chart_box">
        <p class="chart_title">今日详细概况</p>
        <div id="line_chart"></div>
      </div>
      <div class="table_box">
        <div class="table_list">
          <p
            :class="['table_cell', item.id == dayType ? 'active' : '']"
            v-for="(item, index) in dayList"
            :key="index"
            :data-id="item.id"
            @click="chooseType"
          >
            {{ item.title }}
          </p>
        </div>
        <div class="table">
          <div class="table_header">
            <p class="title">日期</p>
            <p class="title">订单数</p>
            <p class="title" v-if="dayType != 'DAY'">日均订单</p>
            <p class="title">订单金额</p>
            <p class="title">客单价</p>
          </div>
          <div class="table_data_list">
            <div class="table_cell" v-for="(item, index) in order" :key="index">
              <p class="cell">{{ item.day | cutOut(5) }}</p>
              <p class="cell">{{ item.ordersCount | intFilter }}</p>
              <p class="cell" v-if="dayType != 'DAY'">
                {{ item.averageOrder | intFilter(2) }}
              </p>
              <p class="cell">{{ item.ordersAmount | intFilter(2) }}</p>
              <p class="cell">{{ item.averageAmount | intFilter(2) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="account_box" v-show="current == 1">
      <div class="account_top">
        <div class="account_title">
          <p class="account_tips">数据统计</p>
          <div @click="jumpDetails">
            <div class="router_arrow">
              <p class="tips">详情</p>
              <img :src="arrow" alt="arrow" class="arrow" />
            </div>
          </div>
        </div>
        <div class="account_list">
          <div class="list_cell">
            <p class="cell_tips">订单总数(单)</p>
            <p class="cell_data">{{ communityData.orderCount | intFilter }}</p>
          </div>
          <div class="list_cell">
            <p class="cell_tips">订单总额(元)</p>
            <p class="cell_data">
              {{ communityData.orderTotalPrice | intFilter(2) }}
            </p>
          </div>
          <div class="list_cell">
            <p class="cell_tips">客单价(元)</p>
            <p class="cell_data">
              {{ communityData.averagePrice | intFilter(2) }}
            </p>
          </div>
        </div>
      </div>
      <div class="table_box">
        <div class="account_table_title">
          <p class="title">{{ $store.state.level | levelVal(1) }}列表</p>
          <p class="table_tips">数据统计{{ updateTime }}</p>
        </div>
        <div class="table account_table">
          <div class="table_header">
            <p class="title">{{ $store.state.level | levelVal(1) }}名称</p>
            <p class="title">订单数</p>
            <p class="title">订单金额</p>
            <p class="title">客单价</p>
          </div>
          <div class="table_data_list">
            <div
              class="table_cell"
              v-for="(item, index) in property"
              :key="index"
            >
              <p
                class="cell property_name"
                v-if="!isLast"
                @click="jumpProperty"
                :data-index="index"
              >
                {{ item.name }}
              </p>
              <p class="cell property_name" v-if="isLast" @click="jumpDetails" :data-index="index" :data-leader="item.leaderId">{{ item.leaderName }}</p>
              <p class="cell">{{ item.orderCount | intFilter }}</p>
              <p class="cell">{{ item.totalPrice | intFilter(2) }}</p>
              <p class="cell">
                {{ (item.totalPrice / item.orderCount) | intFilter(2) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <van-empty v-if="!property || property.length<1" description="暂无信息~" />
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "index",
  data() {
    return {
      arrow: require("@/assets/icons/arrow_right.png"),
      tabbar: [
        {
          title: "数据概览",
          id: 0,
        },
        {
          title: "社区统计",
          id: 1,
        },
      ],
      current: localStorage.getItem('pageTab') || 0,
      yesterday: "", //昨天
      updateTime: "", //更新时间
      today: "", //今天
      communityData: "", //社区电商数据
      dayType: "DAY",
      isLast: false,
      dayList: [
        {
          title: "日",
          id: "DAY",
        },
        {
          title: "周",
          id: "WEEK",
        },
        {
          title: "月",
          id: "MONTH",
        },
      ],
      order: [],
      property: [],
    };
  },
  mounted() {
    const current = this.current;
    if(current == 0){
      this.getHomePageDataOverview();
    }else{
      this.getLevelStatistics();
    }
  },
  methods: {
    // 选项卡
    chooseTab(e) {
      const id = e.currentTarget.dataset.id;
      this.current = id;
      localStorage.setItem('pageTab', id);
      if (id == 0) {
        this.getHomePageDataOverview();
      } else {
        this.getLevelStatistics();
      }
    },
    initChart(params) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("line_chart"));
      // 绘制图表
      myChart.setOption({
        grid: {
          bottom: "10%",
          top: "15%",
        },
        color: ["#307FFF", "#FF7930"],
        legend: {
          show: true,
          icon: "rect",
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            fontSize: 10,
          },
          // right: "5%",
          data: [
            {
              name: "订单量",
              textStyle: {
                color: "#307FFF",
              },
            },
            {
              name: "订单金额",
              textStyle: {
                color: "#FF7930",
              },
            },
          ],
        },
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "line",
          },
        },
        xAxis: {
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            color: "#666",
            fontSize: 20,
          },
          axisLabel: {
            fontSize: 12,
            color: "#666",
          },
          data: params.day,
        },
        yAxis: [
          {
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: "#F5F5F5",
              },
            },
            axisLabel: {
              fontSize: 12,
              color: "#666",
            },
            nameTextStyle: {
              color: "#666",
              fontSize: 12,
            },
            type: "value",
            scale: true,
            name: "/元",
          },
          {
            axisLine: {
              show: false,
            },
            axisLabel: {
              fontSize: 12,
              color: "#666",
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: "#F5F5F5",
              },
            },
            nameTextStyle: {
              color: "#666",
              fontSize: 12,
            },
            type: "value",
            scale: true,
            name: "/单",
          },
        ],
        series: [
          {
            smooth: true,
            name: "订单量",
            type: "line",
            yAxisIndex: 1,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#307FFF", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#fff", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            showSymbol: false,
            data: params.order,
          },
          {
            smooth: true,
            name: "订单金额",
            type: "line",
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#FF7930", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#fff", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            showSymbol: false,
            data: params.account,
          },
        ],
      });
    },
    // 选择日周月
    chooseType(e) {
      const id = e.currentTarget.dataset.id;
      this.dayType = id;
      this.statisticsData(id);
    },
    // 跳转物业详情
    jumpProperty(e) {
      const index = e.currentTarget.dataset.index;
      this.$store.commit("updataCompanyList", this.property);
      this.$router.push({
        path: "property",
        query: {
          id: this.property[index].id,
        },
      });
    },
    // 跳转详情
    jumpDetails(e) {
      if(e.currentTarget.dataset.leader){
        let userData = this.property[e.currentTarget.dataset.index];
        this.$store.commit("updateTitle", userData.leaderName);
        this.$router.push({
          path: "residentialDetails",
          query: {
            id: e.currentTarget.dataset.leader,
            leaderId:e.currentTarget.dataset.leader
          },
        });
      }else{
        let userData = this.$common.getUserData();
        this.$store.commit("updateTitle", userData.companyName);
        this.$router.push({
          path: "residentialDetails",
          query: {
            id: userData.bindId,
          },
        });
      }
      
    },
    // 获取首页数据
    getHomePageDataOverview() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let token = this.$common.getToken();
      this.$api.getHomePageDataOverview({ token }).then((res) => {
        this.yesterday = res.data.yesterday;
        this.today = res.data.today;
        this.updateTime = res.data.updateTime;
        // 表格数据
        const data = {
          day: [],
          order: [],
          account: [],
        };
        for (let i = 1; i < 24; i++) {
          data.day.push(i + ":00");
          data.order.push(
            res.data.detailCount[i] ? res.data.detailCount[i] : 0
          );
          data.account.push(res.data.detailSum[i] ? res.data.detailSum[i] : 0);
        }
        // 初始化图表
        this.initChart(data);
        this.statisticsData("DAY");
      });
    },
    // 获取社区统计详情
    getLevelStatistics() {
      let token = this.$common.getToken();
      let userData = this.$common.getUserData();
      let [bindId, level] = [userData.bindId, userData.level + 1];
      this.isLast = this.$store.state.level == this.$store.state.highestLevel-1;
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });

      if (this.isLast) {
        //最后一级，小区
        this.$api.getLeaderStatistics({ token, bindId }).then(callback);
      } else {
        this.$api.getLevelStatistics({ token, bindId, level }).then(callback);
      }

      let This = this;
      function callback(res) {
        This.$toast.clear();
        This.communityData = {
          orderCount: res.data.orderCount,
          orderTotalPrice: res.data.orderTotalPrice,
          averagePrice: res.data.orderTotalPrice / res.data.orderCount,
        };
        This.updateTime = res.data.updateTime;
        This.property = This.isLast
          ? res.data.leaderStatistics
          : res.data.bindInfo;
      }
    },
    // 获取日周月数据
    statisticsData(type) {
      let token = this.$common.getToken();
      let userData = this.$common.getUserData();
      let [bindId] = [userData.bindId];
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      if (this.$store.state.level == this.$store.state.highestLevel) {
        //最后一级，小区
        this.$api
          .getLeaderStatisticsByType({ token, type, leaderId: bindId })
          .then(callback);
      } else {
        if (type == "DAY") {
          this.$api.statisticsByDay({ token, bindId }).then(callback);
        } else if (type == "WEEK") {
          this.$api.statisticsByWeek({ token, bindId }).then(callback);
        } else if (type == "MONTH") {
          this.$api.statisticsByMonth({ token, bindId }).then(callback);
        }
      }
      let This = this;
      function callback(res) {
        This.$toast.clear();
        let list = res.data;
        let arr = [];
        // 如果是数组
        if (list instanceof Array) {
          list.map((e) => {
            e ? e : (e = {});
            e.ordersAmount == e.ordersAmount ? e.ordersAmount : 0;
            e.ordersCount == e.ordersCount ? e.ordersCount : 0;
            e.averageAmount =
              e.ordersCount == 0 ? 0 : e.ordersAmount / e.ordersCount; //客单价
            e.averageOrder =
              type == "WEEK" ? e.ordersCount / 7 : e.ordersCount / 30; //日均订单
            arr.push(e);
          });
        } else {
          for (let [k, v] of Object.entries(list)) {
            let ordersAmount = v && v.ordersAmount ? v.ordersAmount : 0;
            let ordersCount = v && v.ordersCount ? v.ordersCount : 0;
            arr.push({
              day: k,
              ordersAmount: ordersAmount,
              ordersCount: ordersCount,
              averageAmount: ordersCount == 0 ? 0 : ordersAmount / ordersCount, //客单价
              averageOrder: type == "WEEK" ? ordersCount / 7 : ordersCount / 30, //日均订单
            });
          }
        }
        This.order = arr;
      }
    },
  },
};
</script>
<style scoped lang="less">
@import url("../../../assets/less/mobile/index.less");
@import url("../../../assets/less/mobile/common.less");
</style>