<template>
  <div class="header">
      <img :src="arrow" alt="arrow" class="arrow" @click="reback" v-if="hasBack" />
      <p class="page_title">{{title}}</p>
  </div>
</template>

<script>
export default {
    name: 'SHeader',
    data() {
        return {
            arrow: require('@/assets/icons/arrow.png'),
            hasBack: false,
            title:''
        }
    },
    watch: {
        $route: function(to) {
            const route = to.path;
            const historyLen = window.history.length;
            if(historyLen > 1){
                if(route != '/index' && route != '/mine'){
                    this.title =this.$store.state.title;
                    this.hasBack = true;
                }else{
                    this.title =to.meta.title;
                    this.hasBack = false
                }
            }else{
                this.hasBack = false
            }
        },
        '$store.state.title':function(){
            this.title = this.$store.state.title
        }
    },
    created(){
        // this.title = this.$route.meta.title;
        const route = this.$route.path;
        const historyLen = window.history.length;
        if(historyLen > 1){
            if(route != '/index' && route != '/mine'){
                this.title =this.$store.state.title;
                this.hasBack = true;
            }else{
                this.title =this.$route.meta.title;
                this.hasBack = false
            }
        }else{
            this.hasBack = false
        }
    },
    methods: {
        // 回退
        reback(){
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped lang="less">
.header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88px;
    background-color: #fff;
    .arrow{
        width: 48px;
        height: 48px;
        position: absolute;
        top: 22px;
        left: 30px;
    }
    .page_title{
        font-size: 32px;
        color: #333;
    }
}
</style>