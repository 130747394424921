import Router from 'vue-router'
import router from './router'
import pcRouters from './pc';
import mobileRouters from './mobile';

const windowWidth = window.innerWidth;
const bool = windowWidth > 1024 ? true : false;

const resetRouter = () => {
    const newRouter = new Router({
        routes: bool ? pcRouters : mobileRouters
    });
    router.matcher = newRouter.matcher;
}

export default resetRouter
